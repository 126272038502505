import React from 'react';
import { logoutRedirect } from '../data/gateway';

class SidebarErrorBoundary extends React.Component {
  state: {
    hasError: boolean;
  } = {
    hasError: false,
  };

  static getDerivedStateFromError(err: any) {
    return { hasError: err != null };
  }

  render() {
    if (this.state.hasError) {
      return <div>{logoutRedirect()}</div>;
    }
    return this.props.children;
  }
}

export default SidebarErrorBoundary;
