import { initializeGTM, pushToDataLayer } from "./gtm";

export interface EventData {
    [key: string]: any;
}
export const handleLogin = (userId: string, additionalProperties: object) => {
    initializeGTM({ userId, ...additionalProperties });
};

export const trackEvent = (eventName: string, eventData: EventData) => {
    pushToDataLayer(eventName, eventData); //add category, action , label
};

//trackEvent('button_click', { buttonName: 'Submit' });
