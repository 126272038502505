import React, { Fragment } from "react";

const ErpFrame: React.FC = () => {
    const erpFrameLink =
        "https://gsmshome.netlifesaas.com/web#action=98&active_id=mail.box_inbox&cids=1&menu_id=80";

    return (
        <Fragment>
            <a
                key="erpDiscuss"
                href={erpFrameLink}
                style={{ position: "relative" }}
                target="_blank"
                rel="noreferrer noopener"
            >
                <i
                    className="fas fa-comments"
                    style={{ color: "silver", cursor: "pointer" }}
                />
            </a>
        </Fragment>
    );
};

export default ErpFrame;
