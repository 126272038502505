import { ACTIVE_MENU_KEY, IMenu } from "../../utils/utils";
import React, { Fragment, useRef } from "react";

import { useMenuHelpVideosLink } from "../../data/services/menuHelpVideos";

const MenuHelpVideoLink: React.FC = () => {
    const activeMenuVideoLink = useRef<string>();
    const { data: menuHelpVideoLinkValues, status } = useMenuHelpVideosLink();
    const videoFrame = useRef<HTMLIFrameElement | null>(null);
    if (status === "loading" || menuHelpVideoLinkValues == null) return null;

    const activeMenuValue = sessionStorage.getItem(ACTIVE_MENU_KEY);
    if (activeMenuValue) {
        const existingActiveMenu: IMenu = JSON.parse(activeMenuValue);
        // menuHelpVideoLinkValues.forEach((menuHelpVideo) => {
        //     if (menuHelpVideo.resource === existingActiveMenu.resource) {
        //         activeMenuVideoLink.current = menuHelpVideo.url;
        //     }
        // });
        const activeMenuVideo = menuHelpVideoLinkValues.find(
            (menuHelpVideo) =>
                menuHelpVideo.resource === existingActiveMenu.resource
        );
        activeMenuVideoLink.current = activeMenuVideo?.url;
    }

    const handleVideoFrameVisibility = () => {
        const style = videoFrame.current?.style;
        style?.setProperty(
            "display",
            style?.getPropertyValue("display") ? "" : "none"
        );
    };

    return (
        <Fragment>
            <iframe
                src={activeMenuVideoLink.current}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                    paddingLeft: "30%",
                    paddingTop: "30%",
                    display: "none",
                }}
                title="Help Video"
                ref={videoFrame}
            />
            <i
                className="fas fa-video"
                style={{ color: "green", cursor: "pointer" }}
                hidden={!activeMenuVideoLink.current}
                onClick={handleVideoFrameVisibility}
            />
        </Fragment>
    );
};

export default MenuHelpVideoLink;
