/* eslint-disable */
import "./index.scss";

// import UserOrb from '../UserOrb';
import { useSidebarClosed, useSidebarToggle } from "../Sidebar";

import CompanyOrb from "../CompanyOrb";
import MenuHelpVideoLink from "../MenuHelpVideoLink";
import React, { useCallback } from "react";
import ReleaseNotes from "./ReleaseNotes";
import cx from "classnames";
import NpsHelpdesk from "../../pages/NpsHelpdesk";
import ErpFrame from "../ErpFrame";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

const className = cx([
    "app-header",
    "bg-royal",
    "header-text-light",
    "header-shadow",
]);

interface Props {
    active: boolean;
}

interface RouteParamTypes {
    action?: "helpdesk-ticket";
}

const Hamburger: React.FC<{ active: boolean }> = (props: Props) => {
    const { active = false } = props;

    const className = cx("hamburger hamburger--squeeze", {
        "is-active": active,
    });
    return (
        <button className={className} type="button">
            <span className="hamburger-box">
                <span className="hamburger-inner" />
            </span>
        </button>
    );
};
// DarkLightToggler
const Header: React.FC = () => {
    const sidebarClosed = useSidebarClosed();
    const sidebarToggle = useSidebarToggle();
    const location = useLocation();
    const { action } = useParams() as RouteParamTypes;

    const history = useHistory();
    const historyLocation = location?.pathname.split("/helpdesk-ticket");
    const goBack = useCallback(() => {
        history.push(historyLocation[0]);
    }, [history, historyLocation]);

    return (
        <div className={className}>
            <div className="app-header__logo">
                <div className="logo-src" />
                <div className="toggler-plus-version d-flex flex-column">
                    <ReleaseNotes />
                </div>
                <div
                    style={{ marginTop: "1.2rem" }}
                    className="header__pane ml-auto"
                >
                    <div onClick={sidebarToggle}>
                        <Hamburger active={!sidebarClosed} />
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div className="app-header__logo">
                    <div className="logo-src" />
                </div>
                <div onClick={sidebarToggle}>
                    <Hamburger active={!sidebarClosed} />
                </div>
            </div>
            <div className="app-header__content">
                <div className="app-header-left"></div>
                <div className="app-header-right">
                    <ErpFrame />
                    <NpsHelpdesk action={action} goBack={goBack} />
                    {/* <AvailabilityNotificationAction
                        action={action}
                        goBack={goBack}
                    /> */}
                    <div className="m-2">
                        <Link to={location?.pathname + "/helpdesk-ticket"}>
                            <i
                                title="Help"
                                className="fa fa-question-circle"
                                style={{ fontSize: "16px", color: "white" }}
                            />
                        </Link>
                    </div>
                    <MenuHelpVideoLink />
                    <CompanyOrb />
                    {/* <UserOrb /> */}
                </div>
            </div>
        </div>
    );
};

export default Header;
