import { addCompanyIntercept, gatewayAuthErrorRedirect } from '../gateway';
import axiosBase from 'axios';

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});
addCompanyIntercept(axios);
axios.interceptors.response.use(res => res, gatewayAuthErrorRedirect);

interface GetPutURLResponse {
    putUrl: string;
    getUrl: string;
    isError: boolean;
}

export async function signedFileUpload(
    bucket: string,
    key: string,
    file: File
) {
    const res = await axios.get<GetPutURLResponse>(
        `file/put-url?bucket=${bucket}&key=${key}`
    );
    if (!res.data || res.data.isError)
        throw new Error('Unable to get POST URL');

    await axios.put(res.data.putUrl, file, {
        headers: {
            'Content-Type': file.type,
        },
    });

    return res.data.getUrl;
}

export async function signedFileUploadMock(
    bucket: string,
    key: string,
    file: File
) {
    return `//localhost:9000/favicon.ico?bucket=${bucket}&key=${key}`;
}

export default axios;
