import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { Fragment, useState } from "react";

import { useCompany } from "../../data/services/companies";
import { useAccount } from "../../data/gateway";
import Select from "react-select";

import {
    useNpsHelpdeskAuth,
    useNpsFormBuilder,
} from "../../data/services/npsHelpdesk";

interface HelpdeskProps {
    action?: "helpdesk-ticket";
    goBack: () => void;
}

// interface RouteParamTypes {
//     action?: "helpdesk";
//     // id?: string;
// }

const NpsHelpdesk: React.FC<HelpdeskProps> = (props: HelpdeskProps) => {
    // const { action1 } = useParams() as RouteParamTypes;
    const { action, goBack } = props;
    // const shouldShowHelpdeskWindow = useRef({}) as any;
    localStorage.getItem("showHelpdeskWindow");
    // const [showModel, setShowModel] = useState<boolean>(
    //     shouldShowHelpdeskWindow.current === "true" || false
    // );
    const [requestUrl, setRequestUrl] = useState<string>("");

    let { data: currentCompany } = useCompany() as any;
    const submitDetails = (e: any) => {
        window.open(requestUrl, "_blank")?.focus();
        // localStorage.setItem("showHelpdeskWindow", "false");
        // setShowModel(false);
        goBack();
        setRequestUrl("");
        e.preventDefault();
    };

    const myTickets = (e: any) => {
        window
            .open("https://gsmshome.netlifesaas.com/helpdesk", "_blank")
            ?.focus();
        // localStorage.setItem("showHelpdeskWindow", "false");
        // setShowModel(false);
        goBack();
        setRequestUrl("");
        e.preventDefault();
    };

    const onCancel = () => {
        setRequestUrl("");
        goBack();
        // localStorage.setItem("showHelpdeskWindow", "false");
        // setShowModel(false);
    };

    // useEffect(() => {
    //     shouldShowHelpdeskWindow.current =
    //         localStorage.getItem("showHelpdeskWindow");
    //     if (shouldShowHelpdeskWindow.current === "true") {
    //         setShowModel(true);
    //     } else {
    //         setShowModel(false);
    //     }
    // }, [shouldShowHelpdeskWindow]);

    const { data: authResult } = useNpsHelpdeskAuth();
    const { data: formBuilder } = useNpsFormBuilder(
        authResult ? authResult?.data : null
    );
    const { resolvedData: account } = useAccount();
    let params = `?instance=${window.location.protocol}//${window.location.host}`;
    if (account && account.email) params = params + `?uemail=${account.email}`;
    if (currentCompany && currentCompany.name)
        params = params + `?ucompany=${currentCompany.name}`;

    const options = formBuilder?.data
        .filter((f: any) => f.nes === true)
        .map((i: any) => {
            return {
                value: i.pub_url + params,
                label: i.title,
            };
        });
    return (
        <Fragment>
            {/* <div
                style={{ color: "white", cursor: "pointer", margin: "10px" }}
                onClick={() => setShowModel(true)}
            >
                <i
                    title="Help"
                    className="fa fa-question-circle"
                    style={{ fontSize: "16px" }}
                />
            </div> */}
            <Modal
                key={action}
                isOpen={action === "helpdesk-ticket"}
                fade={false}
                size="lg"
            >
                <form>
                    <ModalHeader
                        style={{
                            background: "white",
                            border: 0,
                            padding: "0.5rem",
                        }}
                    />
                    <ModalBody className="pt-1 px-4">
                        <div className="form-row">
                            <div className="col-md form-group">
                                <div style={{ fontWeight: "bold" }}>Name</div>
                                <div>
                                    {account?.firstName}{" "}
                                    {account?.lastName ? account.lastName : ""}
                                </div>
                            </div>
                            <div className="col-md form-group">
                                <div style={{ fontWeight: "bold" }}>Email</div>
                                <div>{account?.email}</div>
                            </div>
                        </div>

                        {options?.length > 0 ? (
                            <div
                                className="form-row"
                                style={{ fontWeight: "bold" }}
                            >
                                Support Type
                            </div>
                        ) : null}

                        {options?.length > 0 ? (
                            <Select
                                placeholder={"Select"}
                                options={options}
                                onChange={(event: any) =>
                                    setRequestUrl(event.value)
                                }
                                isOptionSelected={(option, selectValue) =>
                                    selectValue.some((i) => i === option)
                                }
                                className={`SelectField`}
                                classNamePrefix="SelectField"
                                isClearable
                            />
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="btn btn-outline-secondary btn-transition"
                            onClick={onCancel}
                            type="button"
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary btn-transition"
                            onClick={submitDetails}
                            disabled={requestUrl === ""}
                        >
                            Add Details
                        </button>
                        <button
                            className="btn btn-primary btn-transition"
                            onClick={myTickets}
                        >
                            My Tickets
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
        </Fragment>
    );
};

export default NpsHelpdesk;
