/* eslint-disable react/prop-types */
import React from "react";

const CompanyImage: React.FC<{ src: string | null }> = ({ src }) =>
    src == null ? (
        <div
            className="rounded-circle bg-white opacity-2 border border-white d-inline-flex align-middle"
            style={{ width: 42, height: 42 }}
        />
    ) : (
        <img
            width={42}
            className="rounded-circle"
            src={src}
            alt="Company Logo"
        />
    );

export default CompanyImage;
