import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    handleCookieConsentAccept,
    handleCookieConsentReject,
} from "../AnalyticsTracking/CookieConsentBanner";
import { IUser } from "../../data/services/user.types";

interface UserPreferencesProps {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    initialData?: IUser;
}

const CONSENT_OPTIONS = ["Accepted", "Rejected"];

const UserPreferences: React.FC<UserPreferencesProps> = (
    props: UserPreferencesProps
) => {
    const { isOpen, initialData, setOpen } = props;
    const [analyticsCookieConsent, setAnalyticsCookieConsent] =
        useState<string>(initialData?.analyticsCookieConsent || "");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (initialData?.analyticsCookieConsent) {
            setAnalyticsCookieConsent(initialData?.analyticsCookieConsent);
        }
    }, [initialData]);

    const handleConsentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setAnalyticsCookieConsent(e.target.value);
    };

    const resetFields = () => {
        setAnalyticsCookieConsent(initialData?.analyticsCookieConsent || "");
        setErrorMessage("");
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");

        try {
            if (analyticsCookieConsent === "Accepted") {
                handleCookieConsentAccept(initialData, setOpen);
            } else if (analyticsCookieConsent === "Rejected") {
                handleCookieConsentReject(initialData, setOpen);
            }

            setOpen(false);
        } catch (error) {
            setErrorMessage("An error occurred while updating preferences.");
        }
    };

    return (
        <Modal
            key={"user-preference"}
            isOpen={isOpen}
            fade={false}
            size="lg"
            onExit={resetFields}
        >
            <form onSubmit={onSubmit}>
                <ModalHeader
                    style={{
                        background: "white",
                        border: 0,
                        padding: "0.5rem",
                    }}
                />
                <ModalBody className="pt-1 px-4">
                    <div className="form-row form-group col-12 col-md">
                        <div className="col form-group">
                            <label htmlFor="analyticsCookieConsent">
                                Cookie Consent
                            </label>
                            <select
                                id="analyticsCookieConsent"
                                className="form-control"
                                defaultValue={
                                    initialData?.analyticsCookieConsent
                                }
                                value={
                                    analyticsCookieConsent ||
                                    initialData?.analyticsCookieConsent ||
                                    ""
                                }
                                onChange={handleConsentChange}
                            >
                                {/* <option value="">Select Consent</option> */}
                                {CONSENT_OPTIONS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {errorMessage && (
                        <Fragment>
                            <hr />
                            <div className="form-row">
                                <div className="col text-danger">
                                    {errorMessage}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-outline-secondary btn-transition"
                        onClick={() => setOpen(false)}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary btn-transition"
                        type="submit"
                    >
                        Update
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default UserPreferences;
