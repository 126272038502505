import { Helmet } from 'react-helmet-async';
import Page from '../components/Page';
import React from 'react';

const NotFound: React.FC = () => (
    <Page>
        <Helmet>
            <title>NetLife SaaS</title>
        </Helmet>

        <div></div>
    </Page>
);

export default NotFound;
