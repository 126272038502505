import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import axios from 'axios';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
    ReactQueryConfigProvider,
    ReactQueryProviderConfig,
} from 'react-query';

axios.defaults.headers.common = {
    Accept: 'application/json, text/plain',
};

const reactQueryConfig: ReactQueryProviderConfig = {
    refetchAllOnWindowFocus: false,
    useErrorBoundary: true,
};

const root = (
    <HelmetProvider>
        <ReactQueryConfigProvider config={reactQueryConfig}>
            <App />
        </ReactQueryConfigProvider>
    </HelmetProvider>
);

ReactDOM.render(root, document.getElementById('root'));

serviceWorker.unregister();
