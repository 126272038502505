import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  onLogout: () => void;
  minutes: number;
}

const MaintenanceWindow: React.FC<Props> = ({
  isOpen,
  onToggle,
  onLogout,
  minutes,
}) => (
  <Modal isOpen={isOpen} fade={false} centered zIndex={1051}>
    <ModalHeader toggle={onToggle} className="bg-warning">
      NetLife Scheduled Maintenance
    </ModalHeader>
    <ModalBody className="bg-white">
      <p>
        NetLife will be conducting scheduled maintenance in{' '}
        <strong>{minutes} minutes</strong>.
      </p>
      <p>
        Please save any pending changes you have made and logout.
        <br />
        You will automatically be logged out when maintenance begins.
      </p>
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-outline-danger btn-transition"
        onClick={onLogout}
      >
        Logout Now
      </button>
      <button className="btn btn-secondary btn-transition" onClick={onToggle}>
        Close
      </button>
    </ModalFooter>
  </Modal>
);

export default MaintenanceWindow;
