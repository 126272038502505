import React from "react";
import cx from "classnames";

import { IUser } from "../../data/services/user.types";

type UserInfoProps = {
    account: IUser;
    widescreen?: boolean;
};

const UserInfo: React.FC<UserInfoProps> = (props: UserInfoProps) => {
    const { account, widescreen = false } = props;
    const className = cx(
        "widget-content-left",
        widescreen && "ml-3 header-user-info"
    );

    return (
        <div className={className}>
            <div className="widget-heading">
                {account?.firstName} {account?.lastName}
            </div>
            {/* <div className="widget-subheading opacity-8">
        {account.authorities.join(', ')}
      </div> */}
        </div>
    );
};

export default UserInfo;
