import TagManager from "react-gtm-module";
import UAParser from "ua-parser-js";

let isGtmInitialized = false;

interface UserProperties {
    userId: string;
    [key: string]: any;
}

const getTechDetails = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    return {
        browser: result.browser.name,
        browserVersion: result.browser.version,
        os: result.os.name,
        osVersion: result.os.version,
        deviceType: result.device.type || "desktop",
        deviceVendor: result.device.vendor || "unknown",
        deviceModel: result.device.model || "unknown",
    };
};

export const initializeGTM = (userProperties?: UserProperties) => {
    const gtmId = process.env.REACT_APP_GTM_ID;
    console.log("##$##", gtmId);

    if (isGtmInitialized || !userProperties) return;

    // TagManager.initialize({
    //     gtmId: "GTM-PBG2GL8",
    //     dataLayer: {
    //         ...userProperties,
    //         userId: userProperties?.userId,
    //         ...techDetails,
    //     },
    // });

    if (!gtmId) {
        console.log("GTM ID is not set in the environment variables.");
        return;
    }
    const techDetails = getTechDetails();

    // if (isGtmInitialized || !userProperties) return;

    TagManager.initialize({
        gtmId,
        dataLayer: {
            ...userProperties,
            userId: userProperties.userId,
            ...techDetails,
        },
    });
    if (gtmId) {
        isGtmInitialized = true;
    }
};

export const pushToDataLayer = (eventName: string, eventData: object) => {
    // if (!isGtmInitialized) return;
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...eventData,
        },
    });
};

export const changeCompany = (companyName: string) => {
    TagManager.dataLayer({
        dataLayer: {
            companyName: companyName,
        },
    });
};
