/* eslint-disable react/display-name */
import "./Checkbox.scss";

import React, { InputHTMLAttributes } from "react";

import cx from "classnames";

export interface CheckboxProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, "defaultValue"> {
    defaultValue?: boolean | string | string[];
    label: string;
    big?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ id, big, label, defaultValue, ...props }, ref) => (
        <div className={cx("custom-control custom-checkbox", { big })}>
            <input
                className="custom-control-input"
                type="checkbox"
                id={id}
                name={id}
                {...props}
                ref={ref}
                defaultChecked={
                    defaultValue != null &&
                    defaultValue !== false &&
                    defaultValue !== "false"
                }
            />
            <label className="custom-control-label" htmlFor={id}>
                {label}
            </label>
        </div>
    )
);

export default Checkbox;
