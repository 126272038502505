import {
    Paginated,
    Sort,
    SortedPaginatedQueryParams,
} from "../sortedPaginated";
import { QueryOptions, queryCache, useMutation, useQuery } from "react-query";
import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import axiosBase, { AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";

import { IChangelog } from "./changelog.type";
import { useQueryWrapper } from "../reactQueryWrapper";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const CHANGELOG_QUERY = "change-logs";
const ACTIVE_CHANGELOG_QUERY = "active-change-log";

const CHANGELOG_QUERY_EXPIRE_TIME = 2000;

interface Params extends SortedPaginatedQueryParams<IChangelog> {
    search?: string;
}

const QUERY_PARAMS: Params = {
    sorts: {},
    pageIndex: 0,
    pageSize: 20,
};

const activeChangelog = async () => {
    try {
        const changelog = await axios.get<IChangelog>(
            `${ACTIVE_CHANGELOG_QUERY}`
        );
        return changelog.data;
    } catch (error) {
        return {} as IChangelog;
    }
};

export const useActiveChangelog = () =>
    useQuery([ACTIVE_CHANGELOG_QUERY], activeChangelog, {
        retry: 0,
        staleTime: 1000 * 3600,
    });

const sortedPaginated = (res?: AxiosResponse<IChangelog[]>) =>
    ({
        total: Number(res?.headers["x-total-count"] || res?.data?.length || 0),
        data: res?.data,
    } as Paginated<IChangelog>);

const prepareQuery = (params: Params) => {
    const param = {
        ...QUERY_PARAMS,
        ...params,
        sorts: {
            ...QUERY_PARAMS.sorts,
            ...params.sorts,
        },
    };
    const sorts = [...Object.entries(param.sorts), ["id", Sort.Ascending]]
        .filter((sort) => sort[1] != null)
        .map((sort) => "sort=" + sort.join(","))
        .join("&");

    return `page=${param.pageIndex}&size=${param.pageSize}&${sorts}${
        params.search != null && params.search.length > 0
            ? "&keyword.contains=" + encodeURIComponent(params.search)
            : ""
    }`;
};

export const useChangelogs = (
    params = QUERY_PARAMS,
    options?: QueryOptions<Paginated<IChangelog>>
) => {
    const query = useMemo(() => prepareQuery(params), [params]);
    const request = useCallback(
        () =>
            axios
                .get<IChangelog[]>(`${CHANGELOG_QUERY}?${query}`)
                .then(sortedPaginated),
        [query]
    );
    return useQueryWrapper<Paginated<IChangelog>, any>(
        [CHANGELOG_QUERY, { query }],
        request,
        {
            ...options,
            retry: 1,
            staleTime: CHANGELOG_QUERY_EXPIRE_TIME,
        }
    );
};

const updateRequest = ({
    data,
    create,
}: {
    data: IChangelog;
    create: boolean;
}) =>
    axios.request({
        url: create ? "/change-log" : "/change-logs/" + data.id,
        method: create ? "POST" : "PUT",
        data,
    });
export const useUpdateChangelog = () =>
    useMutation(updateRequest, {
        onSuccess: (_, { data }) => {
            queryCache.refetchQueries(CHANGELOG_QUERY);
            queryCache.refetchQueries(ACTIVE_CHANGELOG_QUERY, { force: true });
            if (data?.id != null)
                queryCache.setQueryData([CHANGELOG_QUERY, data.id], data);
        },
        useErrorBoundary: false,
        throwOnError: true,
    });

export default axios;
