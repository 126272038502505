import { IPermission, IPermissions } from "./permissions.types";
import { QueryOptions, usePaginatedQuery } from "react-query";
import axiosBase, { AxiosResponse } from "axios";

import { logoutRedirect } from "../gateway";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});

const permissionsRequest = () =>
    axios.get<IPermissions>("permissions").catch((err) => {
        if (
            err.response != null &&
            err.response &&
            err.response.data?.message === "pre:AuthorizationHeaderFilter"
        ) {
            logoutRedirect();
        }
    }) as any;

const PERMISSIONS_QUERY = "PERMISSIONS";
export const usePermissions = (
    options: QueryOptions<AxiosResponse<IPermissions>> = {}
) =>
    usePaginatedQuery<AxiosResponse<IPermissions>, any>(
        [PERMISSIONS_QUERY],
        permissionsRequest,
        {
            ...options,
            retry: 1,
            staleTime: 1000 * 60 * 5, // 5 minute, approx. how long token lasts
        }
    );

export const useResourcePermission = (resource?: string): IPermission => {
    const { resolvedData } = usePermissions();
    if (
        resource == null ||
        resolvedData?.data == null ||
        resolvedData.data[resource] == null
    )
        return {};
    return resolvedData.data[resource];
};
