import React, { useMemo, Fragment } from 'react';
import { FieldError } from 'react-hook-form';
import { Label } from 'reactstrap';
import cx from 'classnames';
import {
  EnhancedFormContextValues,
  pathValue,
} from '../../hooks/useEnhancedForm';
import AllowedHiddenFields from '../../data/itemMasterFields';

const VAR_CHAR_MAX_LEN = 255;

type FormInputOverridenInputAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'form' | 'id'
>;
interface FormInputProps<Data extends Record<string, any>>
  extends FormInputOverridenInputAttributes {
  id: Extract<keyof Data, string>;
  label?: React.ReactNode;
  form: EnhancedFormContextValues<Data>;
  labelClassName?: string;
  errorClassName?: string;
  positiveNumbers?: boolean;
  decimalAllowed?:number;
  validateDomain?:boolean;
}

const FormInput = <Data extends Record<string, any>>({
  id,
  form: {
    register,
    errors,
    formState: { isSubmitting },
    disabled: formDisabled,
    defaultValues,
    labels,
    requiredFields,
    disabledFields,
    rules,
  },
  label,
  labelClassName,
  className,
  errorClassName,
  min,
  max,
  type,
  required: requiredProp,
  step,
  positiveNumbers,
  decimalAllowed,
  validateDomain,
  ...inputProps
}: FormInputProps<Data>): React.ReactElement => {
  const error = errors[id] as FieldError;
  const inputCX = useMemo(
    () => cx('form-control', { 'is-invalid': error != null }, className),
    [error, className]
  );
  const required = useMemo(() => requiredProp || requiredFields[id] === true, [
    id,
    requiredProp,
    requiredFields,
  ]);
  const fieldRules = useMemo(
    () => (rules == null || rules[id] == null ? undefined : rules[id]),
    [rules, id]
  );
  const disabled =
    formDisabled ||
    isSubmitting ||
    inputProps.disabled ||
    disabledFields[id] === true;

  let shouldHide = disabledFields && disabledFields[id] && AllowedHiddenFields.includes(id)
  const defaultValue = useMemo(
    () => (defaultValues == null ? undefined : pathValue(id, defaultValues)),
    [defaultValues, id]
  );

  const handleKeyDown = (e: any) => {
    if (positiveNumbers) {
      if (decimalAllowed === 0 && e.keyCode === 190) {
        return e.preventDefault()
      }
      if (e.keyCode === 189) {
        return e.preventDefault()
      }
    }
  }

  const handleOnBlur = (e: any) => {
    if (validateDomain) {
      if (e.target.value.indexOf(".") < 0) return e.target.value = ""
    }
  }

  const getDecimalPlacesLength = (num : any) => {
    const numStr = String(num);
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length;
    };
    return 0;
  }

  const handleKeyUp = (e: any) => {
    if (positiveNumbers) {
      let checkNumber = parseFloat(e.target.value)
      let decimalLength = getDecimalPlacesLength(checkNumber)
      if (decimalAllowed && decimalLength > decimalAllowed) e.target.value = checkNumber.toFixed(decimalAllowed)
    }
  }
  if (shouldHide) return(<Fragment></Fragment>)
  return (
    <Fragment>
      {label == null && (labels == null || labels[id] == null) ? null : (
        <Label for={id} className={labelClassName}>
          {label != null ? label : labels != null ? labels[id] : undefined}{' '}
          {required ? <span className="text-danger">*</span> : null}
        </Label>
      )}
      <input
        id={id}
        name={id}
        type={type}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleOnBlur}
        className={inputCX}
        ref={register({
          required: required ? 'Required' : undefined,
          min,
          max,
          ...fieldRules,
        })}
        min={min}
        max={max}
        maxLength={
          type == null && inputProps.maxLength == null
            ? VAR_CHAR_MAX_LEN
            : inputProps.maxLength
        }
        defaultValue={defaultValue}
        {...inputProps}
        disabled={disabled || isSubmitting || inputProps.disabled}
        step={type === 'number' && step == null ? 'any' : step}
      />
      {error?.message && (
        <div
          className={cx({ 'invalid-feedback': error != null }, errorClassName)}
        >
          {error?.message}
        </div>
      )}
    </Fragment>
  );
};

export default FormInput;
