import React, { ReactNode } from "react";

import Loader, { LoaderType } from "react-loaders";

interface Props {
    hidden?: boolean;
    children: ReactNode;
    type?: LoaderType;
}
const LoadingIndicator: React.FC<Props> = ({
    hidden = false,
    children,
    type = "ball-grid-beat",
}: Props) => {
    return (
        <div className="loader-container" hidden={hidden}>
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader
                        type={type ? type : "ball-grid-beat"}
                        active={true}
                    />
                </div>
                <h6 className="mt-3">{children}</h6>
            </div>
        </div>
    );
};

export default LoadingIndicator;
