import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
// import { initializeGA } from "./googleAnalytics"; // Custom GA initialization
import { initializeGTM } from "./gtm";
import "./bannerStyle.css";
import {
    getAnalyticsCookieConsent,
    submitAnalyticsCookieConsent,
} from "./cookieConsentServices";
import { useUserProfile } from "../../data/services/user";
import { IUser } from "../../data/services/user.types";

const CONSENT_COOKIE = "analyticsCookieConsent";
export const handleCookieConsentAccept = (
    account: IUser | undefined,
    setBannerVisible: any
) => {
    Cookies.set(CONSENT_COOKIE, "accepted", { expires: 365 * 5 }); // Store in cookies
    setBannerVisible(false);
    if (account?.email) {
        handleGTMInitialization(account);
    }
    triggerApiUpdate("accepted");
};

export const handleCookieConsentReject = (
    account: IUser | undefined,
    setBannerVisible: any
) => {
    Cookies.set(CONSENT_COOKIE, "rejected", { expires: 365 * 5 });
    setBannerVisible(false);
    triggerApiUpdate("rejected");
};

const handleGTMInitialization = (account: IUser) => {
    if (account?.email) {
        initializeGTM({
            userId: account?.email,
            nesUserId: account?.id,
            email: account?.email,
            userName:
                (account?.firstName ? account?.firstName : "") +
                " " +
                (account?.lastName ? account?.lastName : ""),
            userType: account?.type ? account?.type?.name : "",
            userDesignation: account?.designation,
            companyName: account?.company?.name,
            userMainCompany: account?.company?.name,
        });
    }
};

const triggerApiUpdate = async (status: string) => {
    try {
        await submitAnalyticsCookieConsent({ consent: status });
        console.log("User consent status updated successfully");
    } catch (error) {
        console.error("Error updating consent status:", error);
    }
};

const CookieConsentBanner = () => {
    const { data: account, status } = useUserProfile();
    const [isVisible, setIsVisible] = useState(false);

    const handleAccept = () => {
        handleCookieConsentAccept(account?.data, setIsVisible);
    };

    const handleReject = () => {
        handleCookieConsentReject(account?.data, setIsVisible);
    };

    useEffect(() => {
        const checkConsentStatus = async () => {
            const cookieConsent = Cookies.get(CONSENT_COOKIE);

            // If cookie is not present, fetch the consent status from the API
            if (!cookieConsent && status === "success") {
                try {
                    // const response = await axios.get("/api/get-cookie-consent");
                    // const { consent } = response.data;
                    const { consent } = await getAnalyticsCookieConsent();

                    // If consent is "accepted", initialize GA and do not show banner
                    if (consent === "accepted" && account?.data?.email) {
                        Cookies.set(CONSENT_COOKIE, "accepted", {
                            expires: 365 * 5,
                        });
                        handleGTMInitialization(account?.data);
                    }
                    // If consent is "rejected", do nothing (no GA initialization)
                    else if (consent === "rejected") {
                        Cookies.set(CONSENT_COOKIE, "rejected", {
                            expires: 365 * 5,
                        });
                    }
                    // If consent is "unknown", show the banner
                    else {
                        setIsVisible(true);
                    }
                } catch (error) {
                    console.error(
                        "Error fetching cookie consent status:",
                        error
                    );
                    setIsVisible(true); // Show the banner if API call fails
                }
            } else {
                // If consent is already accepted or rejected in cookies
                if (cookieConsent === "accepted" && account?.data?.email) {
                    handleGTMInitialization(account?.data);
                }
            }
        };

        checkConsentStatus();
    }, [account, status]);

    if (!isVisible) return null;

    return (
        <div className="cookie-consent-banner">
            <p>
                We use cookies to improve your experience. Do you accept our
                cookie policy?
            </p>
            <button onClick={() => handleAccept()}>Accept</button>
            <button onClick={() => handleReject()}>Reject</button>
        </div>
    );
};

export default CookieConsentBanner;
