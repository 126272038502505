import "./FileDrop.scss";

import { DropzoneOptions, useDropzone } from "react-dropzone";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import cx from "classnames";
import { toast } from "react-toastify";

export const IMAGE_TYPES = [
    "image/jpeg",
    "image/png",
    "image/bmp",
    ".jpg",
    ".jpeg",
    ".png",
    ".bmp",
    ".gif",
];

interface FileDropProps extends DropzoneOptions {
    width?: number | "auto";
    height?: number;
    previewImage?: boolean;
    multiple?: false;
    buttonOnly?: boolean;
    buttonText?: string;
    buttonClassName?: string;
    checkSize?: boolean | false;
    onRevert: () => void;
    hidden?: boolean | false;
}

const FileDrop: React.FC<FileDropProps> = (props: FileDropProps) => {
    const {
        width,
        height,
        previewImage,
        buttonText = "Replace",
        buttonOnly,
        buttonClassName = "btn btn-primary col no-top-radius",
        checkSize,
        onRevert,
        hidden,
        ...dropProps
    } = props;
    const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
        ...dropProps,
        multiple: false,
    });
    const [previewImageURL, setPreviewImageURL] = useState<string | undefined>(
        undefined
    );

    // When the accepted files change, set up the preview image
    useEffect(() => {
        if (
            !previewImage ||
            acceptedFiles.length !== 1 ||
            !IMAGE_TYPES.includes(acceptedFiles[0].type)
        ) {
            setPreviewImageURL(undefined);
            return;
        }
        const img = new Image();
        img.onload = function () {
            if (acceptedFiles[0] && checkSize) {
                if (img.width <= 120 && img.height <= 120) {
                    setPreviewImageURL(URL.createObjectURL(acceptedFiles[0]));
                    return;
                } else {
                    toast.error(
                        "File exceeds specified size (120x120 px). Please upload the specified size",
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                            className: "mt-5 mr-2",
                        }
                    );
                    setPreviewImageURL(undefined);
                    return;
                }
            }
        };
        img.src = URL.createObjectURL(acceptedFiles[0]);
        if (!checkSize)
            setPreviewImageURL(URL.createObjectURL(acceptedFiles[0]));
    }, [acceptedFiles, previewImage, checkSize]);

    const className = useMemo(
        () =>
            cx("FileDrop", {
                hasImagePreview: previewImageURL != null,
            }),
        [previewImageURL]
    );

    const backgroundImage = useMemo(
        () => (previewImageURL == null ? undefined : `url(${previewImageURL})`),
        [previewImageURL]
    );

    const handleRevert = useCallback(() => {
        onRevert();
        setPreviewImageURL(undefined);
    }, [onRevert]);

    return (
        <div hidden={hidden}>
            {buttonOnly && backgroundImage == null ? (
                <input {...getInputProps()} />
            ) : (
                <div
                    {...getRootProps({ className })}
                    style={{ backgroundImage, width, height }}
                >
                    <input {...getInputProps()} />
                    <p>Drag and drop files here,</p>
                    <p>or click to select files...</p>
                </div>
            )}
            {previewImageURL == null ? null : (
                <button
                    type="button"
                    className="btn btn-square btn-danger col"
                    onClick={handleRevert}
                    disabled={dropProps.disabled}
                >
                    Revert
                </button>
            )}
            <button
                type="button"
                className={buttonClassName}
                onClick={open}
                disabled={dropProps.disabled}
            >
                {buttonText}
            </button>
        </div>
    );
};

export default FileDrop;
