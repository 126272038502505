const AllowedHiddenFields = [
    "cleiNum",
    "technologyTypes",
    "frequencies",
    "oemAltPartNumber",
    "pcbPartNumber",
    "primaryErpCode",
    "secondaryErpCode",
    "repairAndTestCapability",
    "mountType",
    "cpriPortsAndRates",
    "rfOutputs",
    "rfPower",
    "countryOfOrigin",
    "powerType",
    "voltage",
    "ampHours",
    "minWatt",
    "maxWatt",
    "lifespanInMonths",
    "softwareDependency",
    "softwareInfo",
    "minOperatingTemp",
    "maxOperatingTemp",
    "charger",
    "storage",
    "mountType",
    "cpriPortsAndRates",
    "rfOutputs",
    "rfPower",
    "portCount",
] as any;

export default AllowedHiddenFields;
