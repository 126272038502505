import {
    EnhancedFormContextValues,
    NamedId,
    pathValue,
} from "../../hooks/useEnhancedForm";
import React, { Fragment, useMemo, useRef } from "react";

import { FieldError } from "react-hook-form";
import { Label } from "reactstrap";
import { Props as ReactSelectProps } from "react-select";
import SelectField from "../SelectField";
import cx from "classnames";
import AllowedHiddenFields from "../../data/itemMasterFields";

interface FormSelectField<Data extends Record<string, any>>
    extends ReactSelectProps<NamedId> {
    id: Extract<keyof Data, string>;
    label?: React.ReactNode;
    form: EnhancedFormContextValues<Data>;
    labelClassName?: string;
    errorClassName?: string;
    disabled?: boolean;
    lengthSort?: boolean;
    ref?: React.MutableRefObject<null>;
}

export const nameSortAsc = (a: NamedId, b: NamedId) => {
    if (a.name == null) return 1;
    if (b.name == null) return -1;
    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
};
export const nameLengthSortAsc = (a: NamedId, b: NamedId) => {
    if (a.name == null) return 1;
    if (b.name == null) return -1;
    if (a.name.length === b.name.length)
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
    return a.name.length - b.name.length;
};

export const idSortAsc = (a: NamedId, b: NamedId) => {
    if (a.id < b.id) return -1;
    return 1;
};

// eslint-disable-next-line no-redeclare
const FormSelectField = <Data extends Record<string, any>>({
    id,
    label,
    ref,
    form: {
        control,
        errors,
        formState: { isSubmitting },
        selectOptions,
        disabled: formDisabled,
        defaultValues,
        labels,
        requiredFields,
        disabledFields,
        rules,
    },
    labelClassName,
    errorClassName,
    lengthSort,
    ...props
}: FormSelectField<Data>): React.ReactElement => {
    const required = useMemo(
        () => props.required || requiredFields[id] === true,
        [id, props.required, requiredFields]
    );
    const options = useMemo(
        () =>
            props.options != null
                ? (props.options as NamedId[]).sort(
                      lengthSort === true ? idSortAsc : nameSortAsc
                  )
                : selectOptions != null && selectOptions[id] != null
                ? (selectOptions[id] as NamedId[]).sort(
                      lengthSort === true ? idSortAsc : nameSortAsc
                  )
                : undefined,
        [id, lengthSort, props.options, selectOptions]
    );
    const error = errors[id] as FieldError;
    const fieldRules = useMemo(
        () => (rules == null || rules[id] == null ? undefined : rules[id]),
        [rules, id]
    );
    const disabled =
        formDisabled ||
        isSubmitting ||
        props.isDisabled ||
        props.disabled ||
        disabledFields[id] === true;

    let shouldHide =
        disabledFields &&
        disabledFields[id] &&
        AllowedHiddenFields.includes(id);
    const defaultValue = useMemo(
        () =>
            defaultValues == null ? undefined : pathValue(id, defaultValues),
        [defaultValues, id]
    );
    const labelName = useMemo(
        () => (label != null ? label : labels != null ? labels[id] : undefined),
        [id, label, labels]
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const selectRef = ref ?? useRef();
    if (shouldHide) return <Fragment></Fragment>;
    return (
        <Fragment>
            <Label for={id} className={labelClassName}>
                {labelName}{" "}
                {required ? <span className="text-danger">*</span> : null}
            </Label>
            <SelectField
                id={id}
                name={id}
                control={control}
                className={cx({ "is-invalid": error })}
                isDisabled={disabled}
                options={options}
                defaultValue={defaultValue}
                placeholder={`Select${
                    labelName == null ? "" : " " + labelName
                }`}
                rules={{
                    required: required ? "Required" : undefined,
                    ...fieldRules,
                }}
                innerRef={selectRef}
                {...props}
            />
            <div className={cx({ "invalid-feedback": error }, errorClassName)}>
                {error?.message}
            </div>
        </Fragment>
    );
};

export default FormSelectField;
