import { useCallback, useMemo } from "react";
import { QueryOptions, useMutation } from "react-query";
import axiosBase, { AxiosResponse } from "axios";
import { useQueryWrapper, useMutationWrapper } from "../reactQueryWrapper";
import { gatewayAuthErrorRedirect } from "../gateway";
import { Paginated } from "../sortedPaginated";
import { ICompany } from "./companies.types";

const QUERY_EXPIRE_TIME = 2000;

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api/`,
    withCredentials: true,
});
// addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const FEEDBACK_QUERY = "feedback";
const CREATED_BY_QUERY = "createdByOptions";
const COMPANY_OPTIONS_QUERY = "companyOptions";

export interface IFeedback {
    id: number;
    usabilityRating: number;
    featuresRating: number;
    performanceRating: number;
    designRating: number;
    customerSupportRating: number;
    selectedModules: string[];
    comment: string;
    createdBy: string;
    createdByUUID: string;
    createdByEmail: string;
    createdAt: string;
    updatedAt: string;
    company?: ICompany;
}

interface ParamsMin {
    search?: string;
    filters?: string;
    sorts?: { [key: string]: "asc" | "desc" };
    pageIndex?: number;
    pageSize?: number;
    fromCreatedAt?: string | null;
    toCreatedAt?: string | null;
}

const FEEDBACK_PARAMS: ParamsMin = {
    sorts: {},
    pageIndex: 0,
    pageSize: 20,
};

const prepareQuery = (params: ParamsMin) => {
    const param = {
        ...FEEDBACK_PARAMS,
        ...params,
        sorts: {
            ...FEEDBACK_PARAMS.sorts,
            ...params.sorts,
        },
    };

    const sorts = Object.entries(param.sorts || {})
        .map(([key, order]) => `sort=${key},${order}`)
        .join("&");

    return `page=${param.pageIndex}&size=${param.pageSize}&${sorts}${
        param.search
            ? `&keyword.contains=${encodeURIComponent(param.search)}`
            : ""
    }${param.filters ? `&${param.filters}` : ""}${
        param.fromCreatedAt
            ? `&fromCreatedAt.greaterThanOrEqual=${encodeURIComponent(
                  param.fromCreatedAt
              )}`
            : ""
    }${
        param.toCreatedAt
            ? `&toCreatedAt.lessThanOrEqual=${encodeURIComponent(
                  param.toCreatedAt
              )}`
            : ""
    }`;
};

export const useFeedback = (
    params: ParamsMin = FEEDBACK_PARAMS,
    options?: QueryOptions<any>
) => {
    const query = useMemo(() => prepareQuery(params), [params]);

    const requestFeedback = useCallback(
        () =>
            axios.get<any>(`feedback?${query}`).then((res: AxiosResponse) => ({
                total: Number(
                    res.headers["x-total-count"] || res.data.length || 0
                ),
                data: res.data.content,
            })),
        [query]
    );

    return useQueryWrapper<Paginated<IFeedback>, any>(
        [FEEDBACK_QUERY, { query }],
        requestFeedback,
        {
            ...options,
            retry: 1,
            staleTime: QUERY_EXPIRE_TIME,
        }
    );
};

export const useSubmitFeedback = () => {
    const submitFeedbackRequest = useCallback(
        (feedbackData: any) => axios.post("/feedback", feedbackData),
        []
    );

    return useMutationWrapper(submitFeedbackRequest, {
        refetchQueries: [FEEDBACK_QUERY],
    });
};

const deleteFeedbackRequest = (id: number) => axios.delete(`feedback/${id}`);
export const useDeleteFeedback = () => {
    return useMutationWrapper(deleteFeedbackRequest, {
        refetchQueries: [FEEDBACK_QUERY],
    });
};

const updateFeedbackRequest = (feedbackData: any) =>
    axios.put(`feedback/${feedbackData.id}`, feedbackData);
export const useUpdateFeedback = () => {
    return useMutationWrapper(updateFeedbackRequest, {
        refetchQueries: [FEEDBACK_QUERY],
    });
};

export const fetchCreatedByOptions = async () => {
    const response = await axios.get("/feedback/created-by-options");
    return response.data.map((item: any) => ({
        label: item,
        value: item,
    }));
};

export const fetchCompanyOptions = async () => {
    const response = await axios.get("/feedback/company-options");
    return response.data.map((item: any) => ({
        label: item.companyName,
        value: item.companyId,
    }));
};

export const useCreatedByOptions = () => {
    return useQueryWrapper(CREATED_BY_QUERY, fetchCreatedByOptions);
};

export const useCompanyOptions = () => {
    return useQueryWrapper(COMPANY_OPTIONS_QUERY, fetchCompanyOptions);
};

export const exportFeedback = async (params: ParamsMin) => {
    try {
        const query = prepareQuery(params);
        const response = await axios.get(`/feedback/export?${query}`, {
            // params: query,
            responseType: "blob", // Important to handle the binary data
        });

        // Return the file data (Blob)
        return response.data;
    } catch (error) {
        console.error("Error exporting feedback:", error);
        throw error;
    }
};

export const useSendFeedbackReminder = () => {
    return useMutation((reminderData: any) =>
        axios.post("/feedback/set-feedback-reminder", reminderData)
    );
};
