/* eslint-disable */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import Confetti from "react-confetti";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { CategorizedRoutes } from "../../routes";
import { usePermissions } from "../../data/services/permissions";
import { categorizedRoutesToMenuContent } from "../../components/Sidebar/sidebaseUtils";
import {
    useSendFeedbackReminder,
    useSubmitFeedback,
} from "../../data/services/customerFeedback";
import "./CustomerFeedbackStyles.css";

const CustomerFeedback: React.FC = () => {
    const { showFeedback, closeFeedback, moduleLocation } =
        useFeedbackContext();

    const [usabilityRating, setUsabilityRating] = useState<number>(0);
    const [featuresRating, setFeaturesRating] = useState<number>(0);
    const [performanceRating, setPerformanceRating] = useState<number>(0);
    const [designRating, setDesignRating] = useState<number>(0);
    const [customerSupportRating, setCustomerSupportRating] =
        useState<number>(0);

    const [comment, setComment] = useState<string>("");
    const [showConfetti, setShowConfetti] = useState<boolean>(false);
    const [selectedModules, setSelectedModules] = useState<any>();

    const { resolvedData: permissions } = usePermissions();
    const [submitFeedback] = useSubmitFeedback();

    const [mutate] = useSendFeedbackReminder();

    const navItems = useMemo(() => {
        const navItems = categorizedRoutesToMenuContent(
            CategorizedRoutes,
            permissions?.data
        );
        const transformMenu = () => {
            return navItems.flatMap((item) =>
                item.content.map((subitem) => ({
                    label: `${item.label} - ${subitem.label}`,
                    value: `${item.label} - ${subitem.label}`,
                    name: `${item.label} - ${subitem.label}`,
                    id: subitem.to,
                }))
            );
        };
        return transformMenu();
    }, [permissions]);

    const findMenuItemByRoute = useCallback(
        (route: string | undefined) => {
            return navItems.find((item) => item.id === route);
        },
        [navItems]
    );

    useEffect(() => {
        if (findMenuItemByRoute(moduleLocation)) {
            setSelectedModules([findMenuItemByRoute(moduleLocation)]);
        }
    }, [findMenuItemByRoute, moduleLocation]);

    const handleModuleSelection = (selectedOptions: any | null) => {
        setSelectedModules(selectedOptions || []);
    };

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const handleClearWindow = useCallback(() => {
        setUsabilityRating(0);
        setFeaturesRating(0);
        setPerformanceRating(0);
        setDesignRating(0);
        setCustomerSupportRating(0);
        setComment("");
        if (findMenuItemByRoute(moduleLocation)) {
            setSelectedModules([findMenuItemByRoute(moduleLocation)]);
        }
    }, [findMenuItemByRoute, moduleLocation]);

    const handleCloseWindow = useCallback(() => {
        closeFeedback();
        handleClearWindow();
    }, [closeFeedback, handleClearWindow]);

    const handleSubmit = () => {
        if (
            usabilityRating === 0 ||
            featuresRating === 0 ||
            performanceRating === 0 ||
            designRating === 0 ||
            customerSupportRating === 0
        ) {
            Swal.fire({
                title: "Incomplete Feedback",
                text: "Please provide a rating for all categories before submitting.",
                icon: "warning",
                confirmButtonColor: "#2d4c70",
            });
            return;
        }

        const feedbackData = {
            usabilityRating,
            featuresRating,
            performanceRating,
            designRating,
            customerSupportRating,
            selectedModules: selectedModules.map((module: any) => module.name),
            comment,
        };
        submitFeedback(feedbackData, {
            onSuccess: () => {
                Swal.fire({
                    title: "Thank you!",
                    text: "Your feedback has been submitted successfully.",
                    icon: "success",
                    confirmButtonColor: "#2d4c70",
                });
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 3000);
                handleCloseWindow();
            },
            onError: (error) => {
                console.error("Error submitting feedback:", error);
                Swal.fire({
                    title: "Error",
                    text: "There was an error submitting your feedback. Please try again later.",
                    icon: "error",
                    confirmButtonColor: "#2d4c70",
                });
            },
        });
    };

    // const handleMaybeLater = useCallback(() => {
    //     console.log("Sending email with feedback form link");
    //     handleCloseWindow();
    // }, [handleCloseWindow]);

    const handleMaybeLater = useCallback(() => {
        const reminderData = {
            // userEmail: "user@example.com",  // Replace with actual user email
            // userName: "John Doe",           // Replace with actual user's name
            moduleLocation: moduleLocation, // Example: pass module location if necessary
        };

        mutate(reminderData, {
            onSuccess: () => {
                Swal.fire({
                    title: "Reminder Set!",
                    text: "We will send you a reminder email in 5 days.",
                    icon: "success",
                    confirmButtonColor: "#2d4c70",
                });
            },
            onError: () => {
                Swal.fire({
                    title: "Error",
                    text: "There was an error setting the reminder. Please try again later.",
                    icon: "error",
                    confirmButtonColor: "#2d4c70",
                });
            },
        });

        handleCloseWindow();
    }, [moduleLocation, mutate, handleCloseWindow]);

    if (!showFeedback) return null;

    return (
        <>
            {showConfetti && <Confetti />}
            <div
                style={{
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    width: "450px",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                    padding: "30px",
                    margin: "30px",
                    zIndex: 9999,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h2
                        style={{
                            fontSize: "20px",
                            color: "#2d4c70",
                            margin: 0,
                            fontWeight: "bold",
                        }}
                    >
                        We{" "}
                        <span role="img" aria-label="love">
                            ❤️
                        </span>{" "}
                        Feedback!
                    </h2>
                    <button
                        onClick={handleCloseWindow}
                        style={{
                            background: "none",
                            border: "none",
                            fontSize: "16px",
                            cursor: "pointer",
                            color: "#2d4c70",
                        }}
                    >
                        ✕
                    </button>
                </div>

                <RatingComponent
                    rating={usabilityRating}
                    setRating={setUsabilityRating}
                    label="Usability"
                />

                <RatingComponent
                    rating={featuresRating}
                    setRating={setFeaturesRating}
                    label="Features"
                />

                <RatingComponent
                    rating={performanceRating}
                    setRating={setPerformanceRating}
                    label="Performance"
                />

                <RatingComponent
                    rating={designRating}
                    setRating={setDesignRating}
                    label="Design"
                />

                <RatingComponent
                    rating={customerSupportRating}
                    setRating={setCustomerSupportRating}
                    label="Customer Support"
                />

                <div style={{ marginTop: "15px" }}>
                    <p style={{ marginBottom: "5px" }}>
                        Select modules you&apos;ve interacted with:
                    </p>
                    <Select
                        isMulti
                        value={selectedModules}
                        onChange={handleModuleSelection}
                        options={navItems}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>

                <div style={{ marginTop: "15px" }}>
                    <textarea
                        placeholder="Share your feedback"
                        value={comment}
                        onChange={handleCommentChange}
                        rows={4}
                        style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "10px",
                            border: `2px solid #e0e0e0`,
                            resize: "none",
                            transition: "border-color 0.3s ease",
                        }}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "15px",
                    }}
                >
                    <button
                        onClick={handleMaybeLater}
                        style={{
                            padding: "10px 15px",
                            borderRadius: "10px",
                            border: "1px solid #2d4c70",
                            backgroundColor: "white",
                            color: "#2d4c70",
                            cursor: "pointer",
                        }}
                    >
                        Maybe Later 👋
                    </button>
                    <button
                        onClick={handleSubmit}
                        style={{
                            padding: "10px 15px",
                            borderRadius: "10px",
                            border: "none",
                            backgroundColor: "#2d4c70",
                            color: "white",
                            cursor: "pointer",
                        }}
                    >
                        Submit 🚀
                    </button>
                </div>
            </div>
        </>
    );
};

const styles = {
    label: {
        marginBottom: "10px",
        fontSize: "20px",
        color: "#2d4c70",
    },
};

const RatingComponent: React.FC<{
    rating: number;
    setRating: (value: number) => void;
    label: string;
    // eslint-disable-next-line react/prop-types
}> = ({ rating, setRating, label }) => {
    const [hoverRating, setHoverRating] = useState<number | null>(null);

    const handleMouseEnter = (value: number) => {
        setHoverRating(value);
    };

    const handleMouseLeave = () => {
        setHoverRating(null);
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <p style={styles.label}>{label} Rating</p>
            <div style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                {[1, 2, 3, 4, 5].map((value) => (
                    <i
                        key={value}
                        className={`fa fa-star star-icon ${
                            (hoverRating && hoverRating >= value) ||
                            rating >= value
                                ? "active"
                                : ""
                        }`}
                        onClick={() => setRating(value)}
                        onMouseEnter={() => handleMouseEnter(value)}
                        onMouseLeave={handleMouseLeave}
                    />
                ))}
            </div>
        </div>
    );
};

export default CustomerFeedback;
