const hexDigits = '0123456789ABCDEF';

function fastBufferToHex(buffer: ArrayBuffer): string {
  let result = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.length; i++) {
    const byte = bytes[i];
    result += hexDigits[byte >> 4] + hexDigits[byte & 15];
  }
  return result;
}

export function fileBufferHash(
  fileBuffer: ArrayBuffer,
  callback: (hash: string) => void
) {
  const hashType = 'SHA-256';
  const msCrypto: any = (window as any).msCrypto;
  const crypto = window.crypto;
  if (msCrypto != null) {
    // IE 11
    const operation = msCrypto.subtle.digest(
      { name: hashType },
      new Uint8Array(fileBuffer)
    );
    operation.oncomplete = (evt: any) => {
      callback(fastBufferToHex(evt.target.result));
    };
  } else if (crypto != null) {
    crypto.subtle.digest(hashType, fileBuffer).then(digest => {
      callback(fastBufferToHex(digest));
    });
  } else {
    // Unsupported browser
    throw new Error('Browser does not support Crypto API');
  }
}

export default function fileHash(file: File) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise(resolve => {
    reader.onload = () => {
      fileBufferHash(reader.result as ArrayBuffer, resolve);
    };
  });
}
