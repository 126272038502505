export const INVENTORY_RESOURCE =
    "com.netlife.inventoryservice.web.rest.InventoryResource";
export const INVENTORY_IMPORT_RESOURCE =
    "com.netlife.inventoryservice.InventoryImportResource";
export const INVENTORY_ITEM_MASTER_RESOURCE =
    "com.netlife.inventoryservice.InventoryItemProfileDetails";
export const INVENTORY_ADDITIONAL_FILTERS_RESOURCE =
    "com.netlife.inventoryservice.InventoryAdditionalFilters";
export const INVENTORY_AGING_RESOURCE =
    "com.netlife.inventoryservice.InventoryAgingResource";
export const VALUE_ANALYSIS_RESOURCES =
    "com.netlife.itemmaster.web.rest.NetworkAnalysisResource";
export const INVENTORY_ANALYTICS_REQUEST_RESOURCES =
    "com.netlife.itemmaster.web.rest.InventoryAnalyticsRequestResource";
export const INVENTORY_ANALYTICS_ITEM_RESOURCES =
    "com.netlife.itemmaster.web.rest.InventoryAnalyticsItemResource";
export const ITEM_PRICING_RESOURCES = "com.netlife.itemmaster.PricingResource";
export const VALUE_ANALYSIS_RECYCLE_VALUE_RESOURCES =
    "com.netlife.itemmaster.ValueAnalysisRecycleValue";
export const VALUE_ANALYSIS_EXPORT_ADDITIONAL_FIELDS_RESOURCES =
    "com.netlife.itemmaster.ValueAnalysisExportAdditionalFields";
export const MARKET_AVAILABILITY_PART_WISE_DETAILS_RESOURCE =
    "com.netlife.itemmaster.MarketAvailabilityPartWiseDetails";
export const MARKET_AVAILABILITY_RESTRICTED_FIELDS_RESOURCE =
    "com.netlife.itemmaster.MarketAvailabilityRestrictedFields";
export const AFTERMARKET_QUOTE_REQUEST_RESOURCE =
    "com.netlife.itemmaster.AfterMarketQuoteRequest";
export const INVENTORY_REQUEST_RESOURCE =
    "com.netlife.itemmaster.web.rest.InventoryRequestResource";
export const MY_INVENTORY_REQUEST_RESOURCE =
    "com.netlife.itemmaster.UserInventoryRequest";
export const DOWNLOAD_REQUEST_GWP_FIELD_RESOURCE =
    "com.netlife.itemmaster.DownloadRequestGwpFieldRequest";
export const MY_INVENTORY_ORDER_RESOURCE = "com.netlife.itemmaster.UserOrder";
export const INVENTORY_UPLOAD_REQUEST_RESOURCE =
    "com.netlife.itemmaster.web.rest.InventoryRequestAnalysisResource";
export const INVENTORY_UPLOAD_INSTANT_REQUEST_RESOURCE =
    "com.netlife.itemmaster.InventoryInstantRequestAnalysis";
export const INVENTORY_UPLOAD_ANALYST_SUPPORTED_REQUEST_RESOURCE =
    "com.netlife.itemmaster.InventoryAnalystSupportedRequestAnalysis";
// export const ADDITIONAL_INVENTORY_UPLOAD_REQUEST_RESOURCE =
//     "com.netlife.itemmaster.AdditionalInventoryRequestAnalysisResource";

export const INVENTORY_UPLOAD_REQUEST_INTER_OPCO_DATA_RESOURCE =
    "com.netlife.itemmaster.InventoryRequestAnalysisInterOpcoDataResource";
export const ADDITIONAL_INVENTORY_UPLOAD_INTERNAL_GSMS_DATA_RESOURCE =
    "com.netlife.itemmaster.InventoryRequestAnalysisInternalGsmsDataResource";
export const ADDITIONAL_INVENTORY_UPLOAD_OPEN_MARKET_DATA_RESOURCE =
    "com.netlife.itemmaster.InventoryRequestAnalysisOpenMarketDataResource";
export const MY_INVENTORY_REQUEST_INVOICE_RESOURCE =
    "com.netlife.itemmaster.InventoryRequestInvoice";
export const MY_INVENTORY_REQUEST_RELEASE_SHIPMENT_RESOURCE =
    "com.netlife.itemmaster.InventoryRequestReleaseShipment";
export const MARKET_AVAILABILITY_RESOURCE =
    "com.netlife.itemmaster.web.rest.MarketAvailabilityResource";
export const WATCHABLE_INVENTORY_ITEMS_RESOURCE =
    "com.netlife.itemmaster.web.rest.BackOrderNotificationResource";
export const COMPANY_WATCHABLE_INVENTORY_ITEMS_RESOURCE =
    "com.netlife.itemmaster.CompanyBackOrderNotificationResource";
export const QUOTATIONS_RESOURCE =
    "com.netlife.itemmaster.web.rest.RequestForQuoteResource";
export const MARKETPLACE_SHOPPING_CART_RESOURCE =
    "com.netlife.itemmaster.ShoppingCartResource";
export const FIXED_ASSETS_RESOURCE =
    "com.netlife.itemmaster.web.rest.FixedAssetResource";
export const KIT_RESOURCE = "com.netlife.itemmaster.web.rest.KitResource";
export const KIT_STATUS_RESOURCE = "com.netlife.itemmaster.KitStatus";
export const SYSTEM_CONFIGURATION_RESOURCE =
    "com.netlife.itemmaster.web.rest.SystemConfigurationResource";

export const ITEM_MASTER_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemMasterResource";
export const IMPORT_ITEM_MASTER_RESOURCE =
    "com.netlife.itemmaster.ImportItemMaster";
export const BULK_UPDATE_ITEM_RESOURCE =
    "com.netlife.itemmaster.BulkUpdateItems";
export const ITEM_PROFILE_SCORE_RESOURCE =
    "com.netlife.itemmaster.ItemProfileScore";
export const ITEM_STATUS_RESOURCE = "com.netlife.itemmaster.ItemStatus";
export const SUSPECTED_ITEMS_RESOURCE = "com.netlife.itemmaster.SuspectedItems";
export const NETLIFE_VARIABLES_RESOURCE =
    "com.netlife.itemmaster.NetLifeVariables";
export const CREATED_MODIFIEDBY_DETAILS_RESOURCE =
    "com.netlife.itemmaster.CreatedModifiedByDetails";
export const ITEM_MASTER_IMPORT_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemMasterResource";
export const ITEM_MASTER_UPDATE_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemMasterResource";
export const ITEM_MASTER_TYPE_COMPONENT_RESOURCE =
    "com.netlife.itemmaster.web.rest.ComponentItemMasterTypeResource";
export const OEM_RESOURCE = "com.netlife.itemmaster.web.rest.OEMResource";
export const SYSTEM_CATEGORY_RESOURCE =
    "com.netlife.itemmaster.web.rest.SystemCategoryResource";
export const ITEM_CATEGORY_TYPE_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemCategoryTypeResource";
export const SYSTEM_TYPE_RESOURCE =
    "com.netlife.itemmaster.web.rest.SystemTypeResource";
export const INTERNAL_SCREENING_DOC =
    "com.netlife.itemmaster.InternalScreeningDoc";
export const INTERNAL_SCREENING_AND_REPAIR_DOC =
    "com.netlife.itemmaster.InternalScreeningAndRepairDoc";
export const BROCHURE_AND_DATASHEET_DOC =
    "com.netlife.itemmaster.BrochureAndDatasheetDoc";
export const BROCHURE_AND_DATASHEET_AND_PLUS_DOC =
    "com.netlife.itemmaster.BrochureAndDatasheetAndPlusDoc";
export const ITEM_CATEGORY_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemCategoryResource";
export const FREQUENCY_RESOURCE =
    "com.netlife.itemmaster.web.rest.FrequencyResource";
export const TECHNOLOGY_RESOURCE =
    "com.netlife.itemmaster.web.rest.TechnologyTypeResource";
export const VOLTAGE_RESOURCE =
    "com.netlife.itemmaster.web.rest.VoltageResource";
export const CHARGER_RESOURCE =
    "com.netlife.itemmaster.web.rest.ChargerResource";
export const STORAGE_RESOURCE =
    "com.netlife.itemmaster.web.rest.StorageResource";
export const UOM_RESOURCE =
    "com.netlife.itemmaster.web.rest.UnitOfMeasureResource";
export const ITEM_CLASS_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemClassResource";
export const ASSET_CLASS_RESOURCE =
    "com.netlife.itemmaster.web.rest.AssetClassResource";
export const ITEM_CLASS_TYPE_RESOURCE =
    "com.netlife.itemmaster.web.rest.ItemClassTypeResource";
export const DOCUMENT_RESOURCE =
    "com.netlife.itemmaster.web.rest.DocumentResource";

export const EXCHANGED_DEFECTIVE_PART_RESOURCE =
    "com.netlife.inventoryservice.web.rest.ExchangedDefectiveItemResource";
export const DEFECTIVE_PART_ANALYSIS_RESOURCE =
    "com.netlife.inventoryservice.web.rest.DefectivePartAnalysisResource";
export const RMA_TRACKER_RESOURCE =
    "com.netlife.inventoryservice.web.rest.ItemRmaStatusResource";
export const PURCHASED_ITEMS_RESOURCE =
    "com.netlife.inventoryservice.web.rest.PurchasedItemResource";
export const REPLACED_INVENTORY_ITEMS_RESOURCE =
    "com.netlife.inventoryservice.web.rest.ReplacedInventoryItemResource";
export const HARDWARE_SUPPORT_ANALYSIS_RESOURCE =
    "com.netlife.itemmaster.web.rest.HardwareSupportAnalysisResource";
export const ASSET_VALUE_DEFAULTS_RESOURCE =
    "com.netlife.itemmaster.web.rest.AssetValueDefaultResource";
export const INVENTORY_AGING_RULE_RESOURCE =
    "com.netlife.inventoryservice.web.rest.InventoryAgingRulesResource";
export const COMPANY_INVENTORY_REQUEST_FIELDS_RESOURCE =
    "com.netlife.itemmaster.web.rest.CompanyInventoryRequestFieldResource";

export const USERS_RESOURCE = "com.netlife.coreservice.web.rest.UserResource";
export const CONTACTS_RESOURCE =
    "com.netlife.coreservice.web.rest.ContactResource";
export const NOTIFICATION_TYPE_RESOURCE =
    "com.netlife.coreservice.web.rest.NotificationTypeResource";
export const GROUPS_RESOURCE = "com.netlife.coreservice.web.rest.GroupResource";
export const DASHBOARD_RESOURCE =
    "com.netlife.coreservice.web.rest.DashboardResource";
export const USER_MANUALS_RESOURCE =
    "com.netlife.coreservice.web.rest.ManualResource";
export const CHANGELOG_RESOURCE =
    "com.netlife.coreservice.web.rest.ChangeLogResource";
export const USER_ACTIVITY_RESOURCE =
    "com.netlife.coreservice.web.rest.UserActivityResource";
export const COMPANY_RESOURCE =
    "com.netlife.coreservice.web.rest.CompanyResource";
export const CURRENCY_RESOURCE =
    "com.netlife.coreservice.web.rest.CurrencyResource";
export const COMPANY_GROUP_RESOURCE =
    "com.netlife.coreservice.web.rest.CompanyGroupResource";
export const COMPANY_TYPES_RESOURCE =
    "com.netlife.coreservice.web.rest.CompanyTypeResource";
export const COMPANY_NETWORK_RESOURCE =
    "com.netlife.coreservice.web.rest.CompanyNetworkResource";
export const WAREHOUSE_RESOURCE =
    "com.netlife.coreservice.web.rest.WarehouseResource";
export const SOFTWARE_PACKAGES =
    "com.netlife.coreservice.web.rest.SoftwarePackagesResource";
export const ROLES_RESOURCE = "com.netlife.coreservice.web.rest.RolesResource";
export const COUNTRY_RESOURCE =
    "com.netlife.coreservice.web.rest.CountryResource";
export const SITE_TYPES_RESOURCE =
    "com.netlife.coreservice.web.rest.SiteTypeResource";
export const SITES_RESOURCE = "com.netlife.coreservice.web.rest.SiteResource";
export const FEEDBACK_RESOURCE = "com.netlife.coreservice.web.rest.Feedback";

export const COPY_WEB_CONTENTS_RESOURCE =
    "com.netlife.coreservice.ContentCopyAuthorization";
export const S3_UPLOAD_RESOURCE =
    "com.netlife.coreservice.web.rest.S3UploadResource";
export const MENU_HELP_VIDEO_RESOURCE =
    "com.netlife.coreservice.web.rest.MenuHelpVideoResource";
export const SHIPPING_MATRIX_RESOURCE =
    "com.netlife.itemmaster.web.rest.ShippingMatrixResource";
export const ITEM_PCB_RESOURCE = "com.netlife.itemmaster.PCBResource";
export const INVENTORY_PCB_RESOURCE =
    "com.netlife.inventoryservice.PCBResource";

export const ITEM_MASTER_IDENTITY_GENERAL_SECTION =
    "com.netlife.itemmaster.ItemMasterIdentityGeneralSectionResource";
export const ITEM_MASTER_IDENTIFIER_COMPATIBILITY_SECTION =
    "com.netlife.itemmaster.ItemMasterIdentifierCompatibilitySectionResource";

export const ITEM_MASTER_TECHNICAL_ATTRIBUTES_SECTION =
    "com.netlife.itemmaster.ItemMasterTechnicalAttributesSectionResource";
export const ITEM_MASTER_TECHNICAL_DOCS_SECTION =
    "com.netlife.itemmaster.ItemMasterTechnicalDocsSectionResource";

export const ITEM_MASTER_SUPPORT_AVAILABILITY_SECTION =
    "com.netlife.itemmaster.ItemMasterSupportAvailabilitySectionResource";

export const ITEM_MASTER_ENVIRONMENTAL_SECTION =
    "com.netlife.itemmaster.ItemMasterEnvironmentalSectionResource";
export const ITEM_MASTER_TEST_REPAIR_SECTION =
    "com.netlife.itemmaster.ItemMasterTestRepairSectionResource";
export const ITEM_MASTER_PRICING_SECTION =
    "com.netlife.itemmaster.ItemMasterPricingSectionResource";
export const ITEM_MASTER_TYPE_SECTION =
    "com.netlife.itemmaster.ItemMasterTypeSectionResource";
export const REQUESTOR_COMPANY_EDIT_PERMISSION =
    "com.netlife.itemmaster.RequestorCompanyEditResource";
