export interface Paginated<T> {
    total: number;
    data: T[];
}
export enum Sort {
    Ascending = 'asc',
    Descending = 'desc',
}
type Dictionary<T> = { [key: string]: T };
export type Sorts<T extends Dictionary<any>> = {
    [P in Extract<keyof T, string>]?: Sort;
};

export interface SortedPaginatedQueryParams<T extends Dictionary<any>> {
    sorts?: Sorts<T>;
    pageIndex?: number;
    pageSize?: number;
}
