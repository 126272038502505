import {
    AnyQueryKey,
    MutateFunction,
    MutationFunction,
    MutationResultBase,
    QueryFunction,
    QueryOptions,
    QueryResultBase,
    queryCache,
    useMutation,
    useQuery,
} from 'react-query';

interface RefetchQueries {
    refetchQueries: string[];
}

interface WrappedMutationResult<TResults> extends MutationResultBase<TResults> {
    isLoading: boolean;
}

interface WrappedQueryResult<TResult> extends QueryResultBase<TResult> {
    data: TResult | undefined;
    isLoading: boolean;
    isCached: boolean;
}

export function useMutationWrapper<TResults, TVariables = undefined>(
    fn: MutationFunction<TResults, TVariables>,
    arg: RefetchQueries
): [MutateFunction<TResults, TVariables>, WrappedMutationResult<TResults>] {
    const [mutate, mutateState] = useMutation(fn, {
        onSuccess: () => {
            for (const query of arg.refetchQueries) {
                queryCache.refetchQueries(query);
            }
        },
        useErrorBoundary: false,
        throwOnError: true,
    });
    return [
        mutate,
        {
            ...mutateState,
            isLoading: false,
        },
    ];
}

export function useQueryWrapper<TResult, TSingleKey extends string>(
    queryKey:
        | TSingleKey
        | false
        | null
        | undefined
        | (() => TSingleKey | false | null | undefined),
    queryFn: QueryFunction<TResult, [TSingleKey]>,
    config?: QueryOptions<TResult>
): WrappedQueryResult<TResult>;

export function useQueryWrapper<TResult, TKey extends AnyQueryKey>(
    queryKey:
        | TKey
        | false
        | null
        | undefined
        | (() => TKey | false | null | undefined),
    queryFn: QueryFunction<TResult, TKey>,
    config?: QueryOptions<TResult>
): WrappedQueryResult<TResult> {
    const result = useQuery(queryKey, queryFn, config);
    const isLoading = result.status === 'loading' || result.isFetching;
    return {
        ...result,
        data: result.data,
        isLoading,
        isCached: !isLoading,
    };
}
