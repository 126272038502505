import { NamedId } from '../../hooks/useEnhancedForm';
import './SelectField.scss';
import React, { useCallback } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

const handleChange = ([selected]: [number]) => ({ value: selected });
const idLens = (selected: any) => selected.id;
const nameLens = (selected: any) => selected.name || selected.code;
interface SelectFieldProps
    extends ReactSelectProps<NamedId>,
        Omit<ControllerProps, 'onBlur' | 'onChange' | 'defaultValue'> {
    name: string;
    control: any;
    innerRef?: React.MutableRefObject<any>;
}
const SelectField: React.FC<SelectFieldProps> = ({
    name,
    control,
    className,
    innerRef,
    ...props
}) => {
    const refCallback = useCallback(
        ref => {
            if (innerRef != null && control != null && ref != null) {
                innerRef.current = ref.select;
                if (control.selectFields == null) control.selectFields = {};
                control.selectFields[name] = ref.select.inputRef;
            }
        },
        [control, innerRef, name]
    );

    return (
        <Controller
            as={<ReactSelect ref={refCallback} />}
            name={name}
            control={control}
            className={`SelectField ${className}`}
            classNamePrefix="SelectField"
            isClearable={!props.isMulti}
            getOptionValue={idLens}
            getOptionLabel={nameLens}
            {...props}
            onChange={handleChange}
        />
    );
};

export default SelectField;
