import { getResourceFromPath, getRoute } from "../routes";

import { ICompany } from "../data/services/companies.types";

const COMPANY_OVERRIDE_Key = "currentCompany";
export const ACTIVE_MENU_KEY = "activeMenu";

const LOADER_TEXT: string[] = [
    "Did you know that Netlife can handle a full end to end Circular Economy?",
    "It's estimated that a more circular economy could reduce waste generation by as much as 32% by 2030.",
    "The circular economy minimizes the need for extraction and exploitation of minerals and resources.",
    "NetLife Analytics can provide you real-time carbon emissions avoidance data.",
    "Netlife Productivity Suite can track your assets at serial level.",
    "NetLife Item Master Database has over 20 000 records with OEM Product numbers, Technical data, dimensions and much more!",
];

export function getCurrentSelectedCompany() {
    const currentCompany = sessionStorage.getItem(COMPANY_OVERRIDE_Key);
    return currentCompany ? (JSON.parse(currentCompany) as ICompany) : null;
}

export function setCurrentSelectedCompany(value: string) {
    sessionStorage.setItem(COMPANY_OVERRIDE_Key, value);
}

export function clearCurrentCompany() {
    sessionStorage.removeItem(COMPANY_OVERRIDE_Key);
}

export interface IMenu {
    label: string;
    subLabel?: string;
    path?: string;
    route: string;
    resource: string;
}

export function setCurrentSelectedMenu(selectedMenu: IMenu | null) {
    if (!selectedMenu) return;
    sessionStorage.setItem(ACTIVE_MENU_KEY, JSON.stringify(selectedMenu));
}

export function getCurrentSelectedMenu() {
    const cookieValue = sessionStorage.getItem(ACTIVE_MENU_KEY);
    if (cookieValue) {
        const existingActiveMenu: IMenu = JSON.parse(cookieValue);
        return existingActiveMenu;
    }
    const activeMenuPath = getActiveMenuFromPath(window?.location?.pathname);
    setCurrentSelectedMenu(activeMenuPath);
    return activeMenuPath;
}

export function getActiveMenuFromPath(path: string = "/") {
    if (path === "/") {
        return null;
    }
    try {
        const route = getRoute("/".concat(path.split("/")[1]));
        const resource = getResourceFromPath("/".concat(path.split("/")[1]));
        if (Array.isArray(route) && route.length === 5) {
            const selectedMenu = {
                route: route[3],
                label: route?.[0],
                path: path,
                resource: resource ?? "",
            };
            return selectedMenu;
        }
    } catch (error) {
        console.error("Error while finding route from path: ", path, error);
    }
    return null;
}

export const embedSleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const capitalizeFirstLetter = (letter: string) => {
    if (letter.toUpperCase() === "PO RECEIVED") {
        return "PO Received";
    }
    return letter
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
};

export function convertIntoThousandSeparator(value: number): string {
    return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 2, // Ensures that decimal places are shown, adjust as needed
        maximumFractionDigits: 2, // Adjust this as needed for your use case
    })
        .format(value)
        .replace(",", ".");
}

let cachedMessage: string | null = null;
let cacheTime: number = 0;

export const getRandomLoadingMessage = () => {
    const currentTime = Date.now();
    if (cachedMessage && currentTime - cacheTime < 20000) {
        return cachedMessage;
    }
    const randomIndex = Math.floor(Math.random() * LOADER_TEXT.length);
    cachedMessage = LOADER_TEXT[randomIndex];
    cacheTime = currentTime;

    return cachedMessage;
};

function extractErrorMessage(apiResponse: string): string | null {
    const errorMessageRegex = /Error parsing date:.*?;/;
    const match = errorMessageRegex.exec(apiResponse);
    if (match) {
        return match[0];
    } else {
        return null;
    }
}

export const parseErrorMessage = (errorMessage: any) => {
    try {
        if (typeof errorMessage === "string") {
            if (errorMessage.includes("Error parsing date")) {
                const apiErrorMessage = extractErrorMessage(errorMessage);
                if (apiErrorMessage) {
                    return (
                        "The date format in the provided data is incorrect. Please use the format 'dd/MM/yyyy HH:mm:ss' " +
                        apiErrorMessage
                    );
                }
                return "The date format in the provided data is incorrect. Please use the format 'dd/MM/yyyy HH:mm:ss'";
            }
        }
        return errorMessage;
    } catch (error) {
        return "Unexpected error occurred.";
    }
};
