import * as Utils from "../utils/utils";

import { AccountBody, LogoutResponse } from "./gateway.types";
import axiosBase, { AxiosError, AxiosInstance } from "axios";

import { usePaginatedQuery } from "react-query";

const OIDC_PATH =
    process.env.REACT_APP_OIDC_PATH || "/oauth2/authorization/oidc";

export const loginRedirect = () => {
    console.log("--> loginRedirect")
    if (!window?.location) return;
    const activeMenuPath = Utils.getActiveMenuFromPath(
        window?.location?.pathname
    );
    Utils.setCurrentSelectedMenu(activeMenuPath);
    console.log(OIDC_PATH);
    window.location.href = OIDC_PATH;
};

export const isAuthError = (err: AxiosError) =>
    err?.response != null &&
    (err.response.status === 401 ||
        err.response.data?.message === "pre:AuthorizationHeaderFilter");

export const gatewayAuthErrorRedirect = (err: AxiosError) => {
    console.log("--> gatewayAuthErrorRedirect", err)
    console.log("--> gatewayAuthErrorRedirect error code", err?.response?.status)
    console.log("--> gatewayAuthErrorRedirect data path", err.response?.data?.path)
    if (
        err.response?.status === 500 &&
        err.response?.data?.path === "/api/account"
    ) {
        console.log("--> calling logoutRedirect")
        logoutRedirect();
    }
    if (isAuthError(err)) {
        console.log("--> calling loginRedirect")
        loginRedirect();
    }

    return Promise.reject(err);
};

export interface CompanyOverride {
    companyId?: number;
    companyUUID?: string;
}

let companyOverrideSetting: CompanyOverride = {};

export function concatOverride(url?: string) {
    if (!url) return url;
    const currentCompany = Utils.getCurrentSelectedCompany();
    if (!currentCompany || !Object.keys(currentCompany).length) return url;
    const parts = url.split("?");
    const query = `_companyId=${currentCompany.id}&_companyUUID=${currentCompany.companyUUID}`;
    return parts.length === 1
        ? `${url}?${query}`
        : parts[1].includes("_companyId=")
        ? url
        : `${parts[0]}?${query}&${parts[1]}`;
}

export function companyOverride(): CompanyOverride {
    return companyOverrideSetting;
}

export function setCompanyOverride(setting: CompanyOverride | {}) {
    companyOverrideSetting = setting;
}
export function addCompanyIntercept(axios: AxiosInstance) {
    axios.interceptors.request.use((req) => ({
        ...req,
        url: req.url?.startsWith("https://netlife-company-1.s3.amazonaws.com")
            ? req.url
            : concatOverride(req.url),
    }));
}

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/api/`,
    withCredentials: true,
    maxRedirects: 0,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const QUERY_EXPIRE_TIME = 60 * 1000;
const ACCOUNT_QUERY = "account";
const accountRequest = () =>
    axios.get("account").then((response) => response?.data);
export const useAccount = (noAuth: boolean = false) => {
    return usePaginatedQuery<AccountBody, any>(
        noAuth ? null : ACCOUNT_QUERY,
        accountRequest,
        {
            retry: 1,
            staleTime: QUERY_EXPIRE_TIME,
            cacheTime: QUERY_EXPIRE_TIME,
        }
    );
};

const useAccountPaginated = () =>
    usePaginatedQuery<AccountBody, any>([ACCOUNT_QUERY], accountRequest, {
        retry: 1,
        staleTime: QUERY_EXPIRE_TIME,
        cacheTime: QUERY_EXPIRE_TIME,
    });

export const useIsSuperAdmin = () => {
    const { resolvedData: account } = useAccountPaginated();
    return account != null && account.authorities.includes("ROLE_ADMIN");
};

export const logoutRedirect = async () => {
    try {
        console.log("--> logoutRedirect")
        const { data } = await axios.post<LogoutResponse>("logout", {});
        console.log("--> logout response data", data)
        if (window && data && data.logoutUrl && data.idToken) {
            console.log("--> going to logout")
            window.location.href = `${data.logoutUrl}?id_token_hint=${data.idToken}&post_logout_redirect_uri=${window.location.origin}/${OIDC_PATH}`;
        }
    } catch (err) {
        console.error("Unable to logout", err);
        window.location.href = OIDC_PATH;
    } finally {
        Utils.clearCurrentCompany();
    }
};

export default axios;
