import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { isAuthError } from './data/gateway';
import { AxiosError } from 'axios';
import cx from 'classnames';
import React from 'react';

class ErrorBoundary extends React.Component {
    state: {
        hasError: boolean;
        error?: AxiosError;
    } = {
        hasError: false,
        error: undefined,
    };

    static getDerivedStateFromError(err: any) {
        return { hasError: !isAuthError(err), error: err };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    className={cx(
                        'app-container',
                        'app-theme-white',
                        'fixed-header',
                        'fixed-sidebar',
                        'fixed-footer',
                        'body-tabs-shadow-btn'
                    )}
                >
                    <Header />
                    <div className="app-main">
                        <Sidebar />
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-attention icon-gradient bg-love-kiss"></i>
                                            </div>
                                            <div>
                                                {this.state.error?.response
                                                    ?.status === 403
                                                    ? 'Access denied'
                                                    : 'Oops! Something went wrong.'}
                                                <div className="page-title-subheading"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
