import { Col, Row, Tooltip } from "reactstrap";
import {
    IMenu,
    getCurrentSelectedMenu,
    setCurrentSelectedMenu,
} from "../../utils/utils";
import React, { useCallback, useMemo, useState } from "react";

import { NavLink } from "react-router-dom";
import _ from "lodash";
import cx from "classnames";
import { getResourceFromPath } from "../../routes";
import { trackEvent } from "../AnalyticsTracking/eventTrackers";

export type MetisMenuEntries = {
    label: string;
    to: string;
    loadingEntry?: boolean;
    exact?: boolean;
};

export type MetisMenuCategory = {
    content: MetisMenuEntries[];
    label: string;
    icon: string;
    description: string;
    descriptionLink: string;
};

export type MetisMenuContent = MetisMenuCategory[];

export type MetisMenuProps = {
    content?: MetisMenuContent;
};

type DataForTooltip = {
    label: string;
    description: string;
    id?: string;
};

const MetisMenu: React.FC<MetisMenuProps> = (props: MetisMenuProps) => {
    const { content = [] } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const initialDataForTooltip = useMemo(
        () => ({
            label: "",
            description: "",
            id: "",
        }),
        []
    );
    const [tooltipData, setTooltipData] = useState<any>(initialDataForTooltip);

    const [activeMenu, setActiveMenu] = useState<IMenu | null>(
        getCurrentSelectedMenu()
    );
    const [nextSelectedMenu, setNextSelectedMenu] = useState<IMenu | null>();

    const handleMenuClick = useCallback((clickedMenu: IMenu) => {
        setCurrentSelectedMenu(clickedMenu);
        setActiveMenu(clickedMenu);
        trackEvent("click_menu", {
            category: "Menu",
            action: "View",
            label: clickedMenu?.subLabel
                ? "From " + clickedMenu?.subLabel + " menu"
                : "From menu list",
            buttonName: clickedMenu?.subLabel
                ? clickedMenu?.subLabel + " menu"
                : "Menu button",
            moduleName: clickedMenu?.label,
            subModuleName: clickedMenu?.subLabel,
            componentName: clickedMenu?.subLabel,
        });
    }, []);

    const contentMemo = useMemo(
        () =>
            content
                .filter((category) => category.content.length > 0)
                .map((category) => {
                    const { icon, label } = category;
                    const topLevel = category.content.find(
                        (categoryContent) => categoryContent.label === label
                    );
                    const subLevel = category.content.filter(
                        (categoryContent) => categoryContent !== topLevel
                    );

                    const handleMouseEnter = ({
                        description,
                        label,
                    }: DataForTooltip) => {
                        setTooltipData({
                            description,
                            label,
                            id: _.kebabCase(label.replace(/[0-9]/g, "")),
                        });
                    };

                    const handleMouseLeave = () => {
                        setTooltipData({ initialDataForTooltip });
                    };

                    const handleTouchStart = () => {
                        setTooltipOpen(false);
                        setTooltipData({ initialDataForTooltip });
                    };

                    const handleTouchEnd = ({
                        description,
                        label,
                    }: DataForTooltip) => {
                        setTooltipOpen(true);
                        setTooltipData({
                            description,
                            label,
                            id: _.kebabCase(label.replace(/[0-9]/g, "")),
                        });
                    };

                    const isActiveMenuClicked =
                        activeMenu?.label === nextSelectedMenu?.label;
                    const isOtherMenuClicked =
                        nextSelectedMenu?.label === label;
                    return (
                        <li key={label} className={`metismenu-item my-2`}>
                            <Row
                                className={cx(
                                    "d-flex justify-content-between rounded menu",
                                    {
                                        next:
                                            !isActiveMenuClicked &&
                                            isOtherMenuClicked,
                                    }
                                )}
                            >
                                <Col
                                    xs={10.5}
                                    className={cx(
                                        "metismenu-link has-active-child cursor-pointer",
                                        {
                                            selected:
                                                activeMenu?.label === label,
                                            next:
                                                !isActiveMenuClicked &&
                                                isOtherMenuClicked,
                                        }
                                    )}
                                    style={{ width: "75%" }}
                                    onClick={() =>
                                        setNextSelectedMenu({
                                            label: label,
                                            route: "#",
                                            resource: "metismenu",
                                        })
                                    }
                                >
                                    <i className={cx("metismenu-icon", icon)} />
                                    {label}
                                </Col>
                                <Col
                                    xs={0}
                                    className="tooltip-icon cursor-pointer d-flex text-white"
                                    style={{
                                        left: "-2rem",
                                        top: "0.8rem",
                                        fontSize: "0.7rem",
                                        maxWidth: "0.5rem",
                                    }}
                                    id={_.kebabCase(
                                        label.replace(/[0-9]/g, "")
                                    )}
                                    // onClick={() =>
                                    //     window.open(category.descriptionLink)
                                    // }
                                    onMouseEnter={() =>
                                        handleMouseEnter(category)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={() => handleTouchEnd(category)}
                                >
                                    &#9432;
                                </Col>
                            </Row>
                            {subLevel.length > 0 && (
                                <ul
                                    className={cx("metismenu-container", {
                                        visible:
                                            (nextSelectedMenu?.label ??
                                                activeMenu?.label) === label,
                                    })}
                                >
                                    {subLevel.map((categoryContent) => (
                                        <li
                                            key={categoryContent.to}
                                            className={`metismenu-item`}
                                        >
                                            {categoryContent.loadingEntry ? (
                                                <span className="metismenu-link">
                                                    {categoryContent.label}
                                                </span>
                                            ) : (
                                                <NavLink
                                                    to={categoryContent.to}
                                                    className="metismenu-link child"
                                                    activeClassName="active"
                                                    onClick={() =>
                                                        handleMenuClick({
                                                            label: label,
                                                            subLabel:
                                                                categoryContent.label,
                                                            path: categoryContent.to,
                                                            route: categoryContent.to,
                                                            resource:
                                                                getResourceFromPath(
                                                                    categoryContent.to
                                                                ) ?? "",
                                                        })
                                                    }
                                                >
                                                    {categoryContent.label}
                                                </NavLink>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    );
                }),
        [
            content,
            initialDataForTooltip,
            activeMenu,
            handleMenuClick,
            nextSelectedMenu,
        ]
    );

    return (
        <React.Fragment>
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">{contentMemo}</ul>
            </div>
            {tooltipData.label && (
                <Tooltip
                    placement="bottom"
                    target={tooltipData.id}
                    isOpen={tooltipOpen}
                    toggle={toggle}
                    className="main-tooltip"
                    innerClassName="main-tooltip_inner"
                    arrowClassName="main-tooltip_arrow"
                >
                    <p className="main-tooltip_text mt-2">
                        {tooltipData.description}
                    </p>
                    {/* <p>Click to see the detailed description</p> */}
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default MetisMenu;
