import {
    DeepPartial,
    FieldError,
    FormContextValues,
    UseFormOptions,
    ValidationOptions,
    useForm,
} from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

export type FormValues = Record<string, any>;
export interface NamedId {
    id: number | string;
    name?: string;
    status?: ["PENDING", "VALIDATED", "ACTIVE", "INACTIVE", "SUSPECT"];
}

export type SelectOptionsPair<Data extends FormValues> = [
    Extract<keyof Data, string>,
    NamedId[]
];
export type SelectOptionsMap<Data extends FormValues> = Partial<
    Record<keyof Data, NamedId[]>
>;

export type FormLabels<Data extends FormValues> = Partial<
    Record<keyof Data, string>
>;

export interface EnhancedFormOptions<Data extends FormValues>
    extends UseFormOptions<Data> {
    defaultValues: Partial<Data>;
    labels: FormLabels<Data>;
    selectOptionsPairs: SelectOptionsPair<Data>[];
    rules?: Partial<Record<keyof Data, ValidationOptions>>;
    disabled: boolean;
}

export type FieldsSet<Data extends FormValues> = Partial<
    Record<keyof Data, boolean>
>;
export interface EnhancedFormContextValues<Data extends FormValues>
    extends FormContextValues<Data> {
    defaultValues?: Partial<Data>;
    labels?: FormLabels<Data>;
    selectOptions: SelectOptionsMap<Data>;
    rules?: Partial<Record<keyof Data, ValidationOptions>>;
    disabled: boolean;
    requiredFields: FieldsSet<Data>;
    setRequiredFields: (fields: Array<keyof Data>) => void;
    disabledFields: FieldsSet<Data>;
    setDisabledFields: (fields: Array<keyof Data>) => void;
}

function fieldSelectOptions<Data extends FormValues>(
    selectOptionPairs: SelectOptionsPair<Data>[] | undefined | null
): SelectOptionsMap<Data> {
    return selectOptionPairs == null
        ? {}
        : Object.fromEntries<NamedId[]>(selectOptionPairs);
}

const quotSplit = /['"`]/;
const quotStart = /^['"`]/;
export const pathValue = (path: string, obj: any, defaultValue?: any) => {
    const result = path
        .split(/[,[\]]+?/)
        .flatMap((x) =>
            quotStart.test(x) ? x.split(quotSplit) : x.split(/\.+?/)
        )
        .filter(Boolean)
        .reduce((result, key) => (result == null ? result : result[key]), obj);
    return result === undefined || result === obj
        ? obj[path] || defaultValue
        : result;
};

export const useEnhancedForm = <Data extends FormValues>({
    labels,
    selectOptionsPairs,
    defaultValues,
    disabled,
    rules,
    ...formOptions
}: Partial<
    EnhancedFormOptions<Data>
> = {}): EnhancedFormContextValues<Data> => {
    const selectOptions = useMemo(
        () => fieldSelectOptions(selectOptionsPairs),
        [selectOptionsPairs]
    );
    const [requiredState, setRequiredState] = useState<FieldsSet<Data>>({});
    const [disabledFieldsState, setDisabledFieldsState] = useState<
        FieldsSet<Data>
    >({});
    const form = useForm<Data>({ ...formOptions, defaultValues });
    useEffect(() => {
        if (defaultValues != null) {
            form.control.defaultValuesRef.current =
                defaultValues as DeepPartial<Data>;
            if (
                form.control.fieldArrayNamesRef.current != null &&
                form.control.resetFieldArrayFunctionRef.current != null
            ) {
                form.control.fieldArrayNamesRef.current.forEach((name) => {
                    const reset = form.control.resetFieldArrayFunctionRef
                        .current[name] as any;
                    reset();
                });
            }
        }
    }, [
        defaultValues,
        form.control.defaultValuesRef,
        form.control.fieldArrayNamesRef,
        form.control.resetFieldArrayFunctionRef,
    ]);
    const { errors, clearError, reset } = form;
    const fixedReset = useCallback(() => {
        reset();
        form.control.defaultValuesRef.current = {};
        setRequiredState({} as any);
        setDisabledFieldsState({} as any);
    }, [form.control.defaultValuesRef, reset]);

    const setRequiredFields = useCallback(
        (fields?: Array<keyof Data>) => {
            setRequiredState((prevFields) => {
                Object.keys(errors).forEach((field) => {
                    const error = errors[field] as FieldError | undefined;
                    if (
                        error?.type === "required" &&
                        prevFields[field] != null &&
                        !fields?.includes(field)
                    ) {
                        clearError(field);
                    }
                });

                return fields == null
                    ? {}
                    : Object.fromEntries<boolean>(
                          fields.map((field) => [field, true])
                      );
            });
        },
        [clearError, errors]
    );

    const setDisabledFields = useCallback((fields?: Array<keyof Data>) => {
        setDisabledFieldsState(
            fields == null
                ? {}
                : Object.fromEntries<boolean>(
                      fields.map((field) => [field, true])
                  )
        );
    }, []);

    // Modals interrupt focusing after a form error is set due to a reference swap
    // This fixes it by focusing after the swap as an effect
    // WARNING: There's a bit of magic in FormSelectField and SelectField to support using
    // react-select
    useEffect(() => {
        if (form == null || form.errors == null || !form.formState.isSubmitted)
            return;
        for (const field in form.errors) {
            const selectFields = (form.control as any).selectFields;
            let target: any = null;
            if (selectFields != null && selectFields[field] != null) {
                target = selectFields[field];
            } else {
                const fieldError = form.errors[field] as FieldError | undefined;
                target = fieldError?.ref as any;
            }

            if (target?.focus != null) {
                setTimeout(() => {
                    target.focus();
                }, 0);
                break;
            }
        }
        // It should only change on submitCount change (so that form changes don't trigger the effect)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.formState.submitCount]);

    return {
        ...form,
        defaultValues,
        labels,
        selectOptions,
        disabled: disabled === true,
        requiredFields: requiredState,
        setRequiredFields,
        disabledFields: disabledFieldsState,
        setDisabledFields,
        rules,
        reset: fixedReset,
    };
};

export default useEnhancedForm;
