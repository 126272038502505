/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, ReactNode } from "react";

interface FeedbackContextProps {
    showFeedback: boolean;
    openFeedback: () => void;
    closeFeedback: () => void;
    moduleLocation: string | undefined;
    setCurrentModuleLocation: (moduleLocation: string) => void;
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(
    undefined
);

export const FeedbackProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [showFeedback, setShowFeedback] = useState(false);
    const [moduleLocation, setModuleLocation] = useState<string>();

    const openFeedback = () => setShowFeedback(true);
    const closeFeedback = () => setShowFeedback(false);
    const setCurrentModuleLocation = (moduleLocation: string) =>
        setModuleLocation(moduleLocation);

    return (
        <FeedbackContext.Provider
            value={{
                showFeedback,
                openFeedback,
                closeFeedback,
                moduleLocation,
                setCurrentModuleLocation,
            }}
        >
            {children}
        </FeedbackContext.Provider>
    );
};

export const useFeedbackContext = () => {
    const context = useContext(FeedbackContext);
    if (!context) {
        throw new Error("useFeedback must be used within a FeedbackProvider");
    }
    return context;
};
