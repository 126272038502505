import React, { ReactNode } from "react";
import Sidebar, { useSidebarClosed, useSidebarToggle } from "../Sidebar";

import Header from "../Header";
import LoadingIndicator from "../LoadingIndicator";
import cx from "classnames";
import { useAccount } from "../../data/gateway";
import { useWindowWidth } from "@react-hook/window-size/throttled";

interface Props {
    hidden?: boolean;
    noAuth?: boolean;
    flex?: boolean;
    children?: ReactNode;
}

const Page: React.FC<Props> = (props: Props) => {
    const { noAuth = false, flex = false, hidden = false, children } = props;
    const sidebarClosed = useSidebarClosed();
    const { status, resolvedData: data } = useAccount(noAuth);
    const isLoading = status === "loading";
    const width = useWindowWidth();
    const sidebarToggle = useSidebarToggle();
    return (
        <div
            className={cx(
                "app-container",
                "app-theme-white",
                "fixed-header",
                "fixed-sidebar",
                "body-tabs-shadow-btn",
                {
                    "closed-sidebar": sidebarClosed,
                    "sidebar-mobile-open": !sidebarClosed && width < 1250,
                }
            )}
            hidden={hidden}
        >
            <Header />
            <div className="app-main">
                <div
                    className="sidebar-mobile-overlay"
                    onClick={sidebarToggle}
                />
                <Sidebar />
                <div className="app-main__outer">
                    <div
                        className="app-main__inner"
                        style={
                            flex
                                ? {
                                      display: "flex",
                                      margin: 0,
                                      padding: 0,
                                      position: "relative",
                                  }
                                : undefined
                        }
                    >
                        {isLoading ? (
                            <LoadingIndicator>Authenticating</LoadingIndicator>
                        ) : data ? (
                            children
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
