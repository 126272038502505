import React from "react";
import { useCompany } from "../../data/services/companies";
import CompanyImage from "../CompanyImage";
import { DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import UserActionsDropdownMenu from "../UserActionsDropdownMenu";
import { useUserProfile } from "../../data/services/user";
import CookieConsentBanner from "../AnalyticsTracking/CookieConsentBanner";

const CompanyOrb: React.FC = () => {
    const { data: initialData } = useCompany();
    // const { resolvedData: account, status } = useAccount();
    const { data: account, status } = useUserProfile();

    // useEffect(() => {
    //     // Check if user consented earlier and initialize GTM accordingly
    //     // const consentGiven = localStorage.getItem("cookie-consent");
    //     const consentGiven = true;
    //     if (consentGiven && account?.data?.email) {
    //         initializeGTM({
    //             userId: account?.data?.email,
    //             nesUserId: account?.data?.id,
    //             email: account?.data?.email,
    //             userName:
    //                 (account?.data?.firstName ? account?.data?.firstName : "") +
    //                 " " +
    //                 (account?.data?.lastName ? account?.data?.lastName : ""),
    //             // lastName: account?.lastName,
    //             userType: account?.data?.type ? account?.data?.type?.name : "",
    //             userDesignation: account?.data?.designation,
    //             companyName: account?.data?.company?.name,
    //         }); // Initialize with user data if available
    //     }
    // }, [account]);
    if (status === "loading" || account == null) return null;
    return (
        <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div style={{ marginRight: "10px", color: "white" }}>
                        {account?.data?.firstName} {account?.data?.lastName}
                    </div>
                    <div className="widget-content-left">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="link" className="p-0">
                                <CompanyImage src={initialData?.logoUrl!} />
                                <span className="ml-2 opacity-8 fas fa-angle-down" />
                            </DropdownToggle>
                            <UserActionsDropdownMenu account={account?.data} />
                        </UncontrolledButtonDropdown>
                    </div>
                    <CookieConsentBanner />
                    {/* <UserInfo account={account} widescreen /> */}
                </div>
            </div>
        </div>
    );
};

export default CompanyOrb;
