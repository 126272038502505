import axiosBase from "axios";
import {
    addCompanyIntercept,
    gatewayAuthErrorRedirect,
} from "../../data/gateway";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});

addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const COOKIE_CONSENT_QUERY = "analytics-cookies-consent";

export const submitAnalyticsCookieConsent = (data: any) => {
    axios.put(COOKIE_CONSENT_QUERY, data);
};

export const getAnalyticsCookieConsent = () => {
    const data = axios
        .get(COOKIE_CONSENT_QUERY)
        .then((response) => response.data);
    return data;
};
