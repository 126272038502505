import { IPermissions } from "../../data/services/permissions.types";
import { CategorizedRoutesType } from "../../routes";
import { MetisMenuContent } from "../MetisMenu";

export function categorizedRoutesToMenuContent(
    categorizedRoutes: CategorizedRoutesType,
    permissions?: IPermissions
): MetisMenuContent {
    if (permissions == null) return [];
    return categorizedRoutes.map((category) => {
        const [label, icon, description, descriptionLink, routes] = category;
        return {
            label,
            icon,
            description,
            descriptionLink,
            content: routes
                .filter(
                    ([, , , routeOptions]) =>
                        !routeOptions.unlisted &&
                        routeOptions.resource &&
                        routeOptions.needs instanceof Array &&
                        permissions[routeOptions.resource] != null &&
                        routeOptions.needs.every(
                            (need) =>
                                routeOptions.resource != null &&
                                permissions[routeOptions.resource][need] ===
                                    true
                        )
                )
                .map((route) => {
                    const [routeLabel, routePath, , routeOptions] = route;
                    return {
                        label: routeLabel,
                        to:
                            routeOptions?.sidebarLink == null
                                ? routePath
                                : routeOptions?.sidebarLink,
                    };
                }),
        };
    });
}
