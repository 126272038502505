import React, { Fragment } from "react";

import { useActiveChangelog } from "../../data/services/changelog";

interface Props {}

const ReleaseNotes: React.FC<Props> = () => {
    const { data: changelogData } = useActiveChangelog();

    return (
        <Fragment>
            <div className="popover__wrapper" hidden={!changelogData?.version}>
                {/* eslint-disable-next-line */}
                <a className="app-version release popover__title" href="#">
                    v{changelogData?.version}{" "}
                    <i
                        className="fa fa-info-circle"
                        // aria-hidden="true"
                        style={{
                            fontSize: "0.5rem",
                            verticalAlign: "text-top",
                        }}
                    />
                </a>
                <div className="popover__content">
                    <h6>
                        <b>Release Notes:</b>
                    </h6>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: changelogData?.changes || "",
                        }}
                        className="version-notes"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ReleaseNotes;
