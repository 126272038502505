import { CategorizedRoutes, getResourceFromPath } from "../../routes";
import MetisMenu from "../MetisMenu";
import {
    getCurrentSelectedMenu,
    setCurrentSelectedMenu,
} from "../../utils/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";

import CompanyOverrideSelect from "../CompanyOverrideSelect";
import React from "react";
import constate from "constate";
import { usePermissions } from "../../data/services/permissions";
import { categorizedRoutesToMenuContent } from "./sidebaseUtils";

const Sidebar: React.FC = () => {
    const { resolvedData: permissions } = usePermissions();
    // const overrideFix = useCompanyOverride();
    const hist = useHistory();
    const loc = useLocation();
    // Resolve the cached dashboard reports to match against what routes are available exactly once after logging in
    // const { resolvedData: staleDashboardReports } =
    //     useStaleCachedDashboardReports(overrideFix);

    const navItems = useMemo(() => {
        const navItems = categorizedRoutesToMenuContent(
            CategorizedRoutes,
            permissions?.data
        );

        // // Shortcut to prevent re-render for dashboards
        // if (permissions?.data == null || staleDashboardReports?.data == null)
        //     return navItems;

        // const dashboardPerm = permissions?.data[DASHBOARD_RESOURCE];
        // const dashboards: MetisMenuEntries[] =
        //     staleDashboardReports?.data != null
        //         ? staleDashboardReports.data?.map((dash) => ({
        //               label: dash.name,
        //               to: `/dashboards/view/${dash.id}`,
        //               exact: true,
        //           }))
        //         : [{ label: "Loading...", to: "", loadingEntry: true }];

        // if (dashboardPerm?.UPDATE === true) {
        //     dashboards.splice(0, 0, {
        //         label: "Manage",
        //         to: "/dashboards",
        //         exact: true,
        //     });
        // }

        // if (dashboards.length === 0) return navItems;

        // // Insert Dashboard items at the top
        // navItems.splice(0, 0, {
        //     icon: "pe-7s-graph2",
        //     label: "Dashboards",
        //     content: dashboards,
        //     description: toolTip.dashboards,
        //     descriptionLink: "/dashboards-manual",
        // });

        return navItems;
    }, [permissions]);

    // Necessary because not all users have dashboards yet, we need a general root-level redirect
    useEffect(() => {
        if (
            loc.pathname !== "/" ||
            permissions?.data == null ||
            navItems.length === 0
        )
            return;
        const validNavItem = navItems.find((item) => item.content.length > 0);
        if (validNavItem == null) return;
        const existingSelectedMenu = getCurrentSelectedMenu();
        if (!existingSelectedMenu) {
            setCurrentSelectedMenu({
                label: validNavItem.label,
                path: validNavItem.content[0].to,
                route: validNavItem.content[0].to,
                resource: getResourceFromPath(validNavItem.content[0].to) ?? "",
            });
        }
        hist.replace(existingSelectedMenu?.path ?? validNavItem.content[0].to);
    }, [hist, loc.pathname, navItems, permissions]);

    return (
        <div className="app-sidebar sidebar-shadow">
            <div className="scrollbar-container ps">
                <div className="app-sidebar__inner">
                    <CompanyOverrideSelect />
                    <h5 className="app-sidebar__heading">Menu</h5>
                    <MetisMenu content={navItems} />
                </div>
            </div>
        </div>
    );
};

const useSidebarState = ({ initialState = false }) => {
    const [closed, setClosed] = useState(initialState);

    const toggle = useCallback(() => setClosed((prev) => !prev), []);
    return { closed, toggle };
};

export const [SidebarClosedProvider, useSidebarClosed, useSidebarToggle] =
    constate(
        useSidebarState,
        (value) => value.closed,
        (value) => value.toggle
    );

export default withRouter(Sidebar);
