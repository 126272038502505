import React, { useMemo, Fragment } from 'react';
import { FieldError } from 'react-hook-form';
import cx from 'classnames';
import Checkbox, { CheckboxProps } from '../Checkbox';
import {
  EnhancedFormContextValues,
  pathValue,
} from '../../hooks/useEnhancedForm';

type FormInputOverridenInputAttributes = Omit<
  CheckboxProps,
  'form' | 'id' | 'label'
>;
interface FormInputProps<Data extends Record<string, any>>
  extends FormInputOverridenInputAttributes {
  id: Extract<keyof Data, string>;
  label?: string;
  form: EnhancedFormContextValues<Data>;
  labelClassName?: string;
  errorClassName?: string;
}

const FormCheckbox = <Data extends Record<string, any>>({
  id,
  form: {
    register,
    errors,
    formState: { isSubmitting },
    labels,
    defaultValues,
    disabled: formDisabled,
    disabledFields,
  },
  label,
  labelClassName,
  errorClassName,
  type,
  ...checkboxProps
}: FormInputProps<Data>): React.ReactElement => {
  const defaultValue = useMemo(
    () =>
      checkboxProps.defaultValue != null
        ? checkboxProps.defaultValue
        : defaultValues != null && pathValue(id, defaultValues, false),
    [checkboxProps.defaultValue, defaultValues, id]
  );
  const formLabel = useMemo(
    () =>
      label != null
        ? label
        : labels != null && labels[id] != null
        ? labels[id]
        : '',
    [labels, label, id]
  ) as string;
  const error = errors[id] as FieldError;
  const disabled =
    formDisabled ||
    isSubmitting ||
    checkboxProps.disabled ||
    disabledFields[id] === true;
  return (
    <Fragment>
      <Checkbox
        id={id}
        defaultValue={defaultValue}
        label={formLabel}
        {...checkboxProps}
        ref={register}
        disabled={disabled}
      />
      <div
        className={cx({ 'invalid-feedback d-block': error }, errorClassName)}
      >
        {error?.message}
      </div>
    </Fragment>
  );
};

export default FormCheckbox;
